html {
    font-family: sans-serif;
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block
}

audio,
canvas,
progress,
video {
    display: inline-block
}

audio:not([controls]) {
    display: none;
    height: 0
}

progress {
    vertical-align: baseline
}

[hidden],
template {
    display: none
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

a:active,
a:hover {
    outline-width: 0
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

b,
strong {
    font-weight: inherit
}

b,
strong {
    font-weight: bolder
}

dfn {
    font-style: italic
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

mark {
    background-color: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

img {
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

figure {
    margin: 1em 40px
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

button,
input,
optgroup,
select,
textarea {
    font: inherit;
    margin: 0
}

optgroup {
    font-weight: 700
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
    padding: 0
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
    outline: 1px dotted ButtonText
}

fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: .35em .625em .75em
}

legend {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
}

textarea {
    overflow: auto
}

[type=checkbox],
[type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: .54
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden],
template {
    display: none
}

a {
    background-color: transparent
}

a:active,
a:hover {
    outline: 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b,
strong {
    font-weight: 700
}

dfn {
    font-style: italic
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

mark {
    background: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

img {
    border: 0
}

svg:not(:root) {
    overflow: hidden
}

figure {
    margin: 1em 40px
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0
}

pre {
    overflow: auto
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled],
html input[disabled] {
    cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0
}

input {
    line-height: normal
}

input[type=checkbox],
input[type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    height: auto
}

input[type=search] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: .35em .625em .75em
}

legend {
    border: 0;
    padding: 0
}

textarea {
    overflow: auto
}

optgroup {
    font-weight: 700
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

td,
th {
    padding: 0
}

@media print {

    *,
    :after,
    :before {
        background: 0 0 !important;
        color: #000 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        text-shadow: none !important
    }

    a,
    a:visited {
        text-decoration: underline
    }

    a[href]:after {
        content: " (" attr(href) ")"
    }

    abbr[title]:after {
        content: " (" attr(title) ")"
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: ""
    }

    blockquote,
    pre {
        border: 1px solid #999;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    img,
    tr {
        page-break-inside: avoid
    }

    img {
        max-width: 100% !important
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }

    h2,
    h3 {
        page-break-after: avoid
    }

    .navbar {
        display: none
    }

    .btn>.caret,
    .dropup>.btn>.caret {
        border-top-color: #000 !important
    }

    .label {
        border: 1px solid #000
    }

    .table {
        border-collapse: collapse !important
    }

    .table td,
    .table th {
        background-color: #fff !important
    }

    .table-bordered td,
    .table-bordered th {
        border: 1px solid #ddd !important
    }
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

:after,
:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

html {
    font-size: 10px;
    -webkit-tap-highlight-color: transparent
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

a {
    color: #337ab7;
    text-decoration: none
}

a:focus,
a:hover {
    color: #23527c;
    text-decoration: underline
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

figure {
    margin: 0
}

img {
    vertical-align: middle
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto
}

.img-rounded {
    border-radius: 6px
}

.img-thumbnail {
    padding: 4px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    display: inline-block;
    max-width: 100%;
    height: auto
}

.img-circle {
    border-radius: 50%
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

[role=button] {
    cursor: pointer
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit
}

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
    font-weight: 400;
    line-height: 1;
    color: #777
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
    margin-top: 20px;
    margin-bottom: 10px
}

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small {
    font-size: 65%
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
    margin-top: 10px;
    margin-bottom: 10px
}

.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
    font-size: 75%
}

.h1,
h1 {
    font-size: 36px
}

.h2,
h2 {
    font-size: 30px
}

.h3,
h3 {
    font-size: 24px
}

.h4,
h4 {
    font-size: 18px
}

.h5,
h5 {
    font-size: 14px
}

.h6,
h6 {
    font-size: 12px
}

p {
    margin: 0 0 10px
}

.lead {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4
}

@media (min-width:768px) {
    .lead {
        font-size: 21px
    }
}

.small,
small {
    font-size: 85%
}

.mark,
mark {
    background-color: #fcf8e3;
    padding: .2em
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

.text-justify {
    text-align: justify
}

.text-nowrap {
    white-space: nowrap
}

.text-lowercase {
    text-transform: lowercase
}

.text-uppercase {
    text-transform: uppercase
}

.text-capitalize {
    text-transform: capitalize
}

.text-muted {
    color: #777
}

.text-primary {
    color: #337ab7
}

a.text-primary:focus,
a.text-primary:hover {
    color: #286090
}

.text-success {
    color: #3c763d
}

a.text-success:focus,
a.text-success:hover {
    color: #2b542c
}

.text-info {
    color: #31708f
}

a.text-info:focus,
a.text-info:hover {
    color: #245269
}

.text-warning {
    color: #8a6d3b
}

a.text-warning:focus,
a.text-warning:hover {
    color: #66512c
}

.text-danger {
    color: #a94442
}

a.text-danger:focus,
a.text-danger:hover {
    color: #843534
}

.bg-primary {
    color: #fff;
    background-color: #337ab7
}

a.bg-primary:focus,
a.bg-primary:hover {
    background-color: #286090
}

.bg-success {
    background-color: #dff0d8
}

a.bg-success:focus,
a.bg-success:hover {
    background-color: #c1e2b3
}

.bg-info {
    background-color: #d9edf7
}

a.bg-info:focus,
a.bg-info:hover {
    background-color: #afd9ee
}

.bg-warning {
    background-color: #fcf8e3
}

a.bg-warning:focus,
a.bg-warning:hover {
    background-color: #f7ecb5
}

.bg-danger {
    background-color: #f2dede
}

a.bg-danger:focus,
a.bg-danger:hover {
    background-color: #e4b9b9
}

.page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee
}

ol,
ul {
    margin-top: 0;
    margin-bottom: 10px
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none;
    margin-left: -5px
}

.list-inline>li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px
}

dl {
    margin-top: 0;
    margin-bottom: 20px
}

dd,
dt {
    line-height: 1.42857143
}

dt {
    font-weight: 700
}

dd {
    margin-left: 0
}

@media (min-width:768px) {
    .dl-horizontal dt {
        float: left;
        width: 160px;
        clear: left;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .dl-horizontal dd {
        margin-left: 180px
    }
}

abbr[data-original-title],
abbr[title] {
    cursor: help;
    border-bottom: 1px dotted #777
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee
}

blockquote ol:last-child,
blockquote p:last-child,
blockquote ul:last-child {
    margin-bottom: 0
}

blockquote .small,
blockquote footer,
blockquote small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777
}

blockquote .small:before,
blockquote footer:before,
blockquote small:before {
    content: '\2014 \00A0'
}

.blockquote-reverse,
blockquote.pull-right {
    padding-right: 15px;
    padding-left: 0;
    border-right: 5px solid #eee;
    border-left: 0;
    text-align: right
}

.blockquote-reverse .small:before,
.blockquote-reverse footer:before,
.blockquote-reverse small:before,
blockquote.pull-right .small:before,
blockquote.pull-right footer:before,
blockquote.pull-right small:before {
    content: ''
}

.blockquote-reverse .small:after,
.blockquote-reverse footer:after,
.blockquote-reverse small:after,
blockquote.pull-right .small:after,
blockquote.pull-right footer:after,
blockquote.pull-right small:after {
    content: '\00A0 \2014'
}

address {
    margin-bottom: 20px;
    font-style: normal;
    line-height: 1.42857143
}

code,
kbd,
pre,
samp {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace
}

code {
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px
}

kbd {
    padding: 2px 4px;
    font-size: 90%;
    color: #fff;
    background-color: #333;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .25);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .25)
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    -webkit-box-shadow: none;
    box-shadow: none
}

pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    word-break: break-all;
    word-wrap: break-word;
    color: #333;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px
}

pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

@media (min-width:768px) {
    .container {
        width: 750px
    }
}

@media (min-width:992px) {
    .container {
        width: 970px
    }
}

@media (min-width:1200px) {
    .container {
        width: 1170px
    }
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

.row {
    margin-left: -15px;
    margin-right: -15px
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    float: left
}

.col-xs-12 {
    width: 100%
}

.col-xs-11 {
    width: 91.66666667%
}

.col-xs-10 {
    width: 83.33333333%
}

.col-xs-9 {
    width: 75%
}

.col-xs-8 {
    width: 66.66666667%
}

.col-xs-7 {
    width: 58.33333333%
}

.col-xs-6 {
    width: 50%
}

.col-xs-5 {
    width: 41.66666667%
}

.col-xs-4 {
    width: 33.33333333%
}

.col-xs-3 {
    width: 25%
}

.col-xs-2 {
    width: 16.66666667%
}

.col-xs-1 {
    width: 8.33333333%
}

.col-xs-pull-12 {
    right: 100%
}

.col-xs-pull-11 {
    right: 91.66666667%
}

.col-xs-pull-10 {
    right: 83.33333333%
}

.col-xs-pull-9 {
    right: 75%
}

.col-xs-pull-8 {
    right: 66.66666667%
}

.col-xs-pull-7 {
    right: 58.33333333%
}

.col-xs-pull-6 {
    right: 50%
}

.col-xs-pull-5 {
    right: 41.66666667%
}

.col-xs-pull-4 {
    right: 33.33333333%
}

.col-xs-pull-3 {
    right: 25%
}

.col-xs-pull-2 {
    right: 16.66666667%
}

.col-xs-pull-1 {
    right: 8.33333333%
}

.col-xs-pull-0 {
    right: auto
}

.col-xs-push-12 {
    left: 100%
}

.col-xs-push-11 {
    left: 91.66666667%
}

.col-xs-push-10 {
    left: 83.33333333%
}

.col-xs-push-9 {
    left: 75%
}

.col-xs-push-8 {
    left: 66.66666667%
}

.col-xs-push-7 {
    left: 58.33333333%
}

.col-xs-push-6 {
    left: 50%
}

.col-xs-push-5 {
    left: 41.66666667%
}

.col-xs-push-4 {
    left: 33.33333333%
}

.col-xs-push-3 {
    left: 25%
}

.col-xs-push-2 {
    left: 16.66666667%
}

.col-xs-push-1 {
    left: 8.33333333%
}

.col-xs-push-0 {
    left: auto
}

.col-xs-offset-12 {
    margin-left: 100%
}

.col-xs-offset-11 {
    margin-left: 91.66666667%
}

.col-xs-offset-10 {
    margin-left: 83.33333333%
}

.col-xs-offset-9 {
    margin-left: 75%
}

.col-xs-offset-8 {
    margin-left: 66.66666667%
}

.col-xs-offset-7 {
    margin-left: 58.33333333%
}

.col-xs-offset-6 {
    margin-left: 50%
}

.col-xs-offset-5 {
    margin-left: 41.66666667%
}

.col-xs-offset-4 {
    margin-left: 33.33333333%
}

.col-xs-offset-3 {
    margin-left: 25%
}

.col-xs-offset-2 {
    margin-left: 16.66666667%
}

.col-xs-offset-1 {
    margin-left: 8.33333333%
}

.col-xs-offset-0 {
    margin-left: 0
}

@media (min-width:768px) {

    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9 {
        float: left
    }

    .col-sm-12 {
        width: 100%
    }

    .col-sm-11 {
        width: 91.66666667%
    }

    .col-sm-10 {
        width: 83.33333333%
    }

    .col-sm-9 {
        width: 75%
    }

    .col-sm-8 {
        width: 66.66666667%
    }

    .col-sm-7 {
        width: 58.33333333%
    }

    .col-sm-6 {
        width: 50%
    }

    .col-sm-5 {
        width: 41.66666667%
    }

    .col-sm-4 {
        width: 33.33333333%
    }

    .col-sm-3 {
        width: 25%
    }

    .col-sm-2 {
        width: 16.66666667%
    }

    .col-sm-1 {
        width: 8.33333333%
    }

    .col-sm-pull-12 {
        right: 100%
    }

    .col-sm-pull-11 {
        right: 91.66666667%
    }

    .col-sm-pull-10 {
        right: 83.33333333%
    }

    .col-sm-pull-9 {
        right: 75%
    }

    .col-sm-pull-8 {
        right: 66.66666667%
    }

    .col-sm-pull-7 {
        right: 58.33333333%
    }

    .col-sm-pull-6 {
        right: 50%
    }

    .col-sm-pull-5 {
        right: 41.66666667%
    }

    .col-sm-pull-4 {
        right: 33.33333333%
    }

    .col-sm-pull-3 {
        right: 25%
    }

    .col-sm-pull-2 {
        right: 16.66666667%
    }

    .col-sm-pull-1 {
        right: 8.33333333%
    }

    .col-sm-pull-0 {
        right: auto
    }

    .col-sm-push-12 {
        left: 100%
    }

    .col-sm-push-11 {
        left: 91.66666667%
    }

    .col-sm-push-10 {
        left: 83.33333333%
    }

    .col-sm-push-9 {
        left: 75%
    }

    .col-sm-push-8 {
        left: 66.66666667%
    }

    .col-sm-push-7 {
        left: 58.33333333%
    }

    .col-sm-push-6 {
        left: 50%
    }

    .col-sm-push-5 {
        left: 41.66666667%
    }

    .col-sm-push-4 {
        left: 33.33333333%
    }

    .col-sm-push-3 {
        left: 25%
    }

    .col-sm-push-2 {
        left: 16.66666667%
    }

    .col-sm-push-1 {
        left: 8.33333333%
    }

    .col-sm-push-0 {
        left: auto
    }

    .col-sm-offset-12 {
        margin-left: 100%
    }

    .col-sm-offset-11 {
        margin-left: 91.66666667%
    }

    .col-sm-offset-10 {
        margin-left: 83.33333333%
    }

    .col-sm-offset-9 {
        margin-left: 75%
    }

    .col-sm-offset-8 {
        margin-left: 66.66666667%
    }

    .col-sm-offset-7 {
        margin-left: 58.33333333%
    }

    .col-sm-offset-6 {
        margin-left: 50%
    }

    .col-sm-offset-5 {
        margin-left: 41.66666667%
    }

    .col-sm-offset-4 {
        margin-left: 33.33333333%
    }

    .col-sm-offset-3 {
        margin-left: 25%
    }

    .col-sm-offset-2 {
        margin-left: 16.66666667%
    }

    .col-sm-offset-1 {
        margin-left: 8.33333333%
    }

    .col-sm-offset-0 {
        margin-left: 0
    }
}

@media (min-width:992px) {

    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9 {
        float: left
    }

    .col-md-12 {
        width: 100%
    }

    .col-md-11 {
        width: 91.66666667%
    }

    .col-md-10 {
        width: 83.33333333%
    }

    .col-md-9 {
        width: 75%
    }

    .col-md-8 {
        width: 66.66666667%
    }

    .col-md-7 {
        width: 58.33333333%
    }

    .col-md-6 {
        width: 50%
    }

    .col-md-5 {
        width: 41.66666667%
    }

    .col-md-4 {
        width: 33.33333333%
    }

    .col-md-3 {
        width: 25%
    }

    .col-md-2 {
        width: 16.66666667%
    }

    .col-md-1 {
        width: 8.33333333%
    }

    .col-md-pull-12 {
        right: 100%
    }

    .col-md-pull-11 {
        right: 91.66666667%
    }

    .col-md-pull-10 {
        right: 83.33333333%
    }

    .col-md-pull-9 {
        right: 75%
    }

    .col-md-pull-8 {
        right: 66.66666667%
    }

    .col-md-pull-7 {
        right: 58.33333333%
    }

    .col-md-pull-6 {
        right: 50%
    }

    .col-md-pull-5 {
        right: 41.66666667%
    }

    .col-md-pull-4 {
        right: 33.33333333%
    }

    .col-md-pull-3 {
        right: 25%
    }

    .col-md-pull-2 {
        right: 16.66666667%
    }

    .col-md-pull-1 {
        right: 8.33333333%
    }

    .col-md-pull-0 {
        right: auto
    }

    .col-md-push-12 {
        left: 100%
    }

    .col-md-push-11 {
        left: 91.66666667%
    }

    .col-md-push-10 {
        left: 83.33333333%
    }

    .col-md-push-9 {
        left: 75%
    }

    .col-md-push-8 {
        left: 66.66666667%
    }

    .col-md-push-7 {
        left: 58.33333333%
    }

    .col-md-push-6 {
        left: 50%
    }

    .col-md-push-5 {
        left: 41.66666667%
    }

    .col-md-push-4 {
        left: 33.33333333%
    }

    .col-md-push-3 {
        left: 25%
    }

    .col-md-push-2 {
        left: 16.66666667%
    }

    .col-md-push-1 {
        left: 8.33333333%
    }

    .col-md-push-0 {
        left: auto
    }

    .col-md-offset-12 {
        margin-left: 100%
    }

    .col-md-offset-11 {
        margin-left: 91.66666667%
    }

    .col-md-offset-10 {
        margin-left: 83.33333333%
    }

    .col-md-offset-9 {
        margin-left: 75%
    }

    .col-md-offset-8 {
        margin-left: 66.66666667%
    }

    .col-md-offset-7 {
        margin-left: 58.33333333%
    }

    .col-md-offset-6 {
        margin-left: 50%
    }

    .col-md-offset-5 {
        margin-left: 41.66666667%
    }

    .col-md-offset-4 {
        margin-left: 33.33333333%
    }

    .col-md-offset-3 {
        margin-left: 25%
    }

    .col-md-offset-2 {
        margin-left: 16.66666667%
    }

    .col-md-offset-1 {
        margin-left: 8.33333333%
    }

    .col-md-offset-0 {
        margin-left: 0
    }
}

@media (min-width:1200px) {

    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9 {
        float: left
    }

    .col-lg-12 {
        width: 100%
    }

    .col-lg-11 {
        width: 91.66666667%
    }

    .col-lg-10 {
        width: 83.33333333%
    }

    .col-lg-9 {
        width: 75%
    }

    .col-lg-8 {
        width: 66.66666667%
    }

    .col-lg-7 {
        width: 58.33333333%
    }

    .col-lg-6 {
        width: 50%
    }

    .col-lg-5 {
        width: 41.66666667%
    }

    .col-lg-4 {
        width: 33.33333333%
    }

    .col-lg-3 {
        width: 25%
    }

    .col-lg-2 {
        width: 16.66666667%
    }

    .col-lg-1 {
        width: 8.33333333%
    }

    .col-lg-pull-12 {
        right: 100%
    }

    .col-lg-pull-11 {
        right: 91.66666667%
    }

    .col-lg-pull-10 {
        right: 83.33333333%
    }

    .col-lg-pull-9 {
        right: 75%
    }

    .col-lg-pull-8 {
        right: 66.66666667%
    }

    .col-lg-pull-7 {
        right: 58.33333333%
    }

    .col-lg-pull-6 {
        right: 50%
    }

    .col-lg-pull-5 {
        right: 41.66666667%
    }

    .col-lg-pull-4 {
        right: 33.33333333%
    }

    .col-lg-pull-3 {
        right: 25%
    }

    .col-lg-pull-2 {
        right: 16.66666667%
    }

    .col-lg-pull-1 {
        right: 8.33333333%
    }

    .col-lg-pull-0 {
        right: auto
    }

    .col-lg-push-12 {
        left: 100%
    }

    .col-lg-push-11 {
        left: 91.66666667%
    }

    .col-lg-push-10 {
        left: 83.33333333%
    }

    .col-lg-push-9 {
        left: 75%
    }

    .col-lg-push-8 {
        left: 66.66666667%
    }

    .col-lg-push-7 {
        left: 58.33333333%
    }

    .col-lg-push-6 {
        left: 50%
    }

    .col-lg-push-5 {
        left: 41.66666667%
    }

    .col-lg-push-4 {
        left: 33.33333333%
    }

    .col-lg-push-3 {
        left: 25%
    }

    .col-lg-push-2 {
        left: 16.66666667%
    }

    .col-lg-push-1 {
        left: 8.33333333%
    }

    .col-lg-push-0 {
        left: auto
    }

    .col-lg-offset-12 {
        margin-left: 100%
    }

    .col-lg-offset-11 {
        margin-left: 91.66666667%
    }

    .col-lg-offset-10 {
        margin-left: 83.33333333%
    }

    .col-lg-offset-9 {
        margin-left: 75%
    }

    .col-lg-offset-8 {
        margin-left: 66.66666667%
    }

    .col-lg-offset-7 {
        margin-left: 58.33333333%
    }

    .col-lg-offset-6 {
        margin-left: 50%
    }

    .col-lg-offset-5 {
        margin-left: 41.66666667%
    }

    .col-lg-offset-4 {
        margin-left: 33.33333333%
    }

    .col-lg-offset-3 {
        margin-left: 25%
    }

    .col-lg-offset-2 {
        margin-left: 16.66666667%
    }

    .col-lg-offset-1 {
        margin-left: 8.33333333%
    }

    .col-lg-offset-0 {
        margin-left: 0
    }
}

table {
    background-color: transparent
}

caption {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #777;
    text-align: left
}

th {
    text-align: left
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd
}

.table>caption+thead>tr:first-child>td,
.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>td,
.table>thead:first-child>tr:first-child>th {
    border-top: 0
}

.table>tbody+tbody {
    border-top: 2px solid #ddd
}

.table .table {
    background-color: #fff
}

.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th {
    padding: 5px
}

.table-bordered {
    border: 1px solid #ddd
}

.table-bordered>tbody>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border: 1px solid #ddd
}

.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border-bottom-width: 2px
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f9f9f9
}

.table-hover>tbody>tr:hover {
    background-color: #f5f5f5
}

table col[class*=col-] {
    position: static;
    float: none;
    display: table-column
}

table td[class*=col-],
table th[class*=col-] {
    position: static;
    float: none;
    display: table-cell
}

.table>tbody>tr.active>td,
.table>tbody>tr.active>th,
.table>tbody>tr>td.active,
.table>tbody>tr>th.active,
.table>tfoot>tr.active>td,
.table>tfoot>tr.active>th,
.table>tfoot>tr>td.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>thead>tr.active>th,
.table>thead>tr>td.active,
.table>thead>tr>th.active {
    background-color: #f5f5f5
}

.table-hover>tbody>tr.active:hover>td,
.table-hover>tbody>tr.active:hover>th,
.table-hover>tbody>tr:hover>.active,
.table-hover>tbody>tr>td.active:hover,
.table-hover>tbody>tr>th.active:hover {
    background-color: #e8e8e8
}

.table>tbody>tr.success>td,
.table>tbody>tr.success>th,
.table>tbody>tr>td.success,
.table>tbody>tr>th.success,
.table>tfoot>tr.success>td,
.table>tfoot>tr.success>th,
.table>tfoot>tr>td.success,
.table>tfoot>tr>th.success,
.table>thead>tr.success>td,
.table>thead>tr.success>th,
.table>thead>tr>td.success,
.table>thead>tr>th.success {
    background-color: #dff0d8
}

.table-hover>tbody>tr.success:hover>td,
.table-hover>tbody>tr.success:hover>th,
.table-hover>tbody>tr:hover>.success,
.table-hover>tbody>tr>td.success:hover,
.table-hover>tbody>tr>th.success:hover {
    background-color: #d0e9c6
}

.table>tbody>tr.info>td,
.table>tbody>tr.info>th,
.table>tbody>tr>td.info,
.table>tbody>tr>th.info,
.table>tfoot>tr.info>td,
.table>tfoot>tr.info>th,
.table>tfoot>tr>td.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>thead>tr.info>th,
.table>thead>tr>td.info,
.table>thead>tr>th.info {
    background-color: #d9edf7
}

.table-hover>tbody>tr.info:hover>td,
.table-hover>tbody>tr.info:hover>th,
.table-hover>tbody>tr:hover>.info,
.table-hover>tbody>tr>td.info:hover,
.table-hover>tbody>tr>th.info:hover {
    background-color: #c4e3f3
}

.table>tbody>tr.warning>td,
.table>tbody>tr.warning>th,
.table>tbody>tr>td.warning,
.table>tbody>tr>th.warning,
.table>tfoot>tr.warning>td,
.table>tfoot>tr.warning>th,
.table>tfoot>tr>td.warning,
.table>tfoot>tr>th.warning,
.table>thead>tr.warning>td,
.table>thead>tr.warning>th,
.table>thead>tr>td.warning,
.table>thead>tr>th.warning {
    background-color: #fcf8e3
}

.table-hover>tbody>tr.warning:hover>td,
.table-hover>tbody>tr.warning:hover>th,
.table-hover>tbody>tr:hover>.warning,
.table-hover>tbody>tr>td.warning:hover,
.table-hover>tbody>tr>th.warning:hover {
    background-color: #faf2cc
}

.table>tbody>tr.danger>td,
.table>tbody>tr.danger>th,
.table>tbody>tr>td.danger,
.table>tbody>tr>th.danger,
.table>tfoot>tr.danger>td,
.table>tfoot>tr.danger>th,
.table>tfoot>tr>td.danger,
.table>tfoot>tr>th.danger,
.table>thead>tr.danger>td,
.table>thead>tr.danger>th,
.table>thead>tr>td.danger,
.table>thead>tr>th.danger {
    background-color: #f2dede
}

.table-hover>tbody>tr.danger:hover>td,
.table-hover>tbody>tr.danger:hover>th,
.table-hover>tbody>tr:hover>.danger,
.table-hover>tbody>tr>td.danger:hover,
.table-hover>tbody>tr>th.danger:hover {
    background-color: #ebcccc
}

.table-responsive {
    overflow-x: auto;
    min-height: .01%
}

@media screen and (max-width:767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd
    }

    .table-responsive>.table {
        margin-bottom: 0
    }

    .table-responsive>.table>tbody>tr>td,
    .table-responsive>.table>tbody>tr>th,
    .table-responsive>.table>tfoot>tr>td,
    .table-responsive>.table>tfoot>tr>th,
    .table-responsive>.table>thead>tr>td,
    .table-responsive>.table>thead>tr>th {
        white-space: nowrap
    }

    .table-responsive>.table-bordered {
        border: 0
    }

    .table-responsive>.table-bordered>tbody>tr>td:first-child,
    .table-responsive>.table-bordered>tbody>tr>th:first-child,
    .table-responsive>.table-bordered>tfoot>tr>td:first-child,
    .table-responsive>.table-bordered>tfoot>tr>th:first-child,
    .table-responsive>.table-bordered>thead>tr>td:first-child,
    .table-responsive>.table-bordered>thead>tr>th:first-child {
        border-left: 0
    }

    .table-responsive>.table-bordered>tbody>tr>td:last-child,
    .table-responsive>.table-bordered>tbody>tr>th:last-child,
    .table-responsive>.table-bordered>tfoot>tr>td:last-child,
    .table-responsive>.table-bordered>tfoot>tr>th:last-child,
    .table-responsive>.table-bordered>thead>tr>td:last-child,
    .table-responsive>.table-bordered>thead>tr>th:last-child {
        border-right: 0
    }

    .table-responsive>.table-bordered>tbody>tr:last-child>td,
    .table-responsive>.table-bordered>tbody>tr:last-child>th,
    .table-responsive>.table-bordered>tfoot>tr:last-child>td,
    .table-responsive>.table-bordered>tfoot>tr:last-child>th {
        border-bottom: 0
    }
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700
}

input[type=search] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

input[type=checkbox],
input[type=radio] {
    margin: 4px 0 0;
    line-height: normal
}

input[type=file] {
    display: block
}

input[type=range] {
    display: block;
    width: 100%
}

select[multiple],
select[size] {
    height: auto
}

input[type=checkbox]:focus,
input[type=file]:focus,
input[type=radio]:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

output {
    display: block;
    padding-top: 7px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6)
}

.form-control::-moz-placeholder {
    color: #999;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #999
}

.form-control::-webkit-input-placeholder {
    color: #999
}

.form-control::-ms-expand {
    border: 0;
    background-color: transparent
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #eee;
    opacity: 1
}

.form-control[disabled],
fieldset[disabled] .form-control {
    cursor: not-allowed
}

textarea.form-control {
    height: auto
}

input[type=search] {
    -webkit-appearance: none
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

    input[type=date].form-control,
    input[type=datetime-local].form-control,
    input[type=month].form-control,
    input[type=time].form-control {
        line-height: 34px
    }

    .input-group-sm input[type=date],
    .input-group-sm input[type=datetime-local],
    .input-group-sm input[type=month],
    .input-group-sm input[type=time],
    input[type=date].input-sm,
    input[type=datetime-local].input-sm,
    input[type=month].input-sm,
    input[type=time].input-sm {
        line-height: 30px
    }

    .input-group-lg input[type=date],
    .input-group-lg input[type=datetime-local],
    .input-group-lg input[type=month],
    .input-group-lg input[type=time],
    input[type=date].input-lg,
    input[type=datetime-local].input-lg,
    input[type=month].input-lg,
    input[type=time].input-lg {
        line-height: 46px
    }
}

.form-group {
    margin-bottom: 15px
}

.checkbox,
.radio {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px
}

.checkbox label,
.radio label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer
}

.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.radio input[type=radio],
.radio-inline input[type=radio] {
    position: absolute;
    margin-left: -20px
}

.checkbox+.checkbox,
.radio+.radio {
    margin-top: -5px
}

.checkbox-inline,
.radio-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: 400;
    cursor: pointer
}

.checkbox-inline+.checkbox-inline,
.radio-inline+.radio-inline {
    margin-top: 0;
    margin-left: 10px
}

fieldset[disabled] input[type=checkbox],
fieldset[disabled] input[type=radio],
input[type=checkbox].disabled,
input[type=checkbox][disabled],
input[type=radio].disabled,
input[type=radio][disabled] {
    cursor: not-allowed
}

.checkbox-inline.disabled,
.radio-inline.disabled,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .radio-inline {
    cursor: not-allowed
}

.checkbox.disabled label,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .radio label {
    cursor: not-allowed
}

.form-control-static {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0;
    min-height: 34px
}

.form-control-static.input-lg,
.form-control-static.input-sm {
    padding-left: 0;
    padding-right: 0
}

.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

select.input-sm {
    height: 30px;
    line-height: 30px
}

select[multiple].input-sm,
textarea.input-sm {
    height: auto
}

.form-group-sm .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

.form-group-sm select.form-control {
    height: 30px;
    line-height: 30px
}

.form-group-sm select[multiple].form-control,
.form-group-sm textarea.form-control {
    height: auto
}

.form-group-sm .form-control-static {
    height: 30px;
    min-height: 32px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1.5
}

.input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

select.input-lg {
    height: 46px;
    line-height: 46px
}

select[multiple].input-lg,
textarea.input-lg {
    height: auto
}

.form-group-lg .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

.form-group-lg select.form-control {
    height: 46px;
    line-height: 46px
}

.form-group-lg select[multiple].form-control,
.form-group-lg textarea.form-control {
    height: auto
}

.form-group-lg .form-control-static {
    height: 46px;
    min-height: 38px;
    padding: 11px 16px;
    font-size: 18px;
    line-height: 1.3333333
}

.has-feedback {
    position: relative
}

.has-feedback .form-control {
    padding-right: 42.5px
}

.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none
}

.form-group-lg .form-control+.form-control-feedback,
.input-group-lg+.form-control-feedback,
.input-lg+.form-control-feedback {
    width: 46px;
    height: 46px;
    line-height: 46px
}

.form-group-sm .form-control+.form-control-feedback,
.input-group-sm+.form-control-feedback,
.input-sm+.form-control-feedback {
    width: 30px;
    height: 30px;
    line-height: 30px
}

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label {
    color: #3c763d
}

.has-success .form-control {
    border-color: #3c763d;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-success .form-control:focus {
    border-color: #2b542c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #67b168;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #67b168
}

.has-success .input-group-addon {
    color: #3c763d;
    border-color: #3c763d;
    background-color: #dff0d8
}

.has-success .form-control-feedback {
    color: #3c763d
}

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label {
    color: #8a6d3b
}

.has-warning .form-control {
    border-color: #8a6d3b;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-warning .form-control:focus {
    border-color: #66512c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #c0a16b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #c0a16b
}

.has-warning .input-group-addon {
    color: #8a6d3b;
    border-color: #8a6d3b;
    background-color: #fcf8e3
}

.has-warning .form-control-feedback {
    color: #8a6d3b
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
    color: #a94442
}

.has-error .form-control {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-error .form-control:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483
}

.has-error .input-group-addon {
    color: #a94442;
    border-color: #a94442;
    background-color: #f2dede
}

.has-error .form-control-feedback {
    color: #a94442
}

.has-feedback label~.form-control-feedback {
    top: 25px
}

.has-feedback label.sr-only~.form-control-feedback {
    top: 0
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373
}

@media (min-width:768px) {
    .form-inline .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-static {
        display: inline-block
    }

    .form-inline .input-group {
        display: inline-table;
        vertical-align: middle
    }

    .form-inline .input-group .form-control,
    .form-inline .input-group .input-group-addon,
    .form-inline .input-group .input-group-btn {
        width: auto
    }

    .form-inline .input-group>.form-control {
        width: 100%
    }

    .form-inline .control-label {
        margin-bottom: 0;
        vertical-align: middle
    }

    .form-inline .checkbox,
    .form-inline .radio {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle
    }

    .form-inline .checkbox label,
    .form-inline .radio label {
        padding-left: 0
    }

    .form-inline .checkbox input[type=checkbox],
    .form-inline .radio input[type=radio] {
        position: relative;
        margin-left: 0
    }

    .form-inline .has-feedback .form-control-feedback {
        top: 0
    }
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 7px
}

.form-horizontal .checkbox,
.form-horizontal .radio {
    min-height: 27px
}

.form-horizontal .form-group {
    margin-left: -15px;
    margin-right: -15px
}

@media (min-width:768px) {
    .form-horizontal .control-label {
        text-align: right;
        margin-bottom: 0;
        padding-top: 7px
    }
}

.form-horizontal .has-feedback .form-control-feedback {
    right: 15px
}

@media (min-width:768px) {
    .form-horizontal .form-group-lg .control-label {
        padding-top: 11px;
        font-size: 18px
    }
}

@media (min-width:768px) {
    .form-horizontal .form-group-sm .control-label {
        padding-top: 6px;
        font-size: 12px
    }
}

.fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear
}

.fade.in {
    opacity: 1
}

.collapse {
    display: none
}

.collapse.in {
    display: block
}

tr.collapse.in {
    display: table-row
}

tbody.collapse.in {
    display: table-row-group
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: .35s;
    transition-duration: .35s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent
}

.dropdown,
.dropup {
    position: relative
}

.dropdown-toggle:focus {
    outline: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    background-clip: padding-box
}

.dropdown-menu.pull-right {
    right: 0;
    left: auto
}

.dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5
}

.dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    background-color: #337ab7
}

.dropdown-menu>.disabled>a,
.dropdown-menu>.disabled>a:focus,
.dropdown-menu>.disabled>a:hover {
    color: #777
}

.dropdown-menu>.disabled>a:focus,
.dropdown-menu>.disabled>a:hover {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    cursor: not-allowed
}

.open>.dropdown-menu {
    display: block
}

.open>a {
    outline: 0
}

.dropdown-menu-right {
    left: auto;
    right: 0
}

.dropdown-menu-left {
    left: 0;
    right: auto
}

.dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #777;
    white-space: nowrap
}

.dropdown-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 990
}

.pull-right>.dropdown-menu {
    right: 0;
    left: auto
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    content: ""
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px
}

@media (min-width:768px) {
    .navbar-right .dropdown-menu {
        left: auto;
        right: 0
    }

    .navbar-right .dropdown-menu-left {
        left: 0;
        right: auto
    }
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate
}

.input-group[class*=col-] {
    float: none;
    padding-left: 0;
    padding-right: 0
}

.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0
}

.input-group .form-control:focus {
    z-index: 3
}

.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

select.input-group-lg>.form-control,
select.input-group-lg>.input-group-addon,
select.input-group-lg>.input-group-btn>.btn {
    height: 46px;
    line-height: 46px
}

select[multiple].input-group-lg>.form-control,
select[multiple].input-group-lg>.input-group-addon,
select[multiple].input-group-lg>.input-group-btn>.btn,
textarea.input-group-lg>.form-control,
textarea.input-group-lg>.input-group-addon,
textarea.input-group-lg>.input-group-btn>.btn {
    height: auto
}

.input-group-sm>.form-control,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.btn {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

select.input-group-sm>.form-control,
select.input-group-sm>.input-group-addon,
select.input-group-sm>.input-group-btn>.btn {
    height: 30px;
    line-height: 30px
}

select[multiple].input-group-sm>.form-control,
select[multiple].input-group-sm>.input-group-addon,
select[multiple].input-group-sm>.input-group-btn>.btn,
textarea.input-group-sm>.form-control,
textarea.input-group-sm>.input-group-addon,
textarea.input-group-sm>.input-group-btn>.btn {
    height: auto
}

.input-group .form-control,
.input-group-addon,
.input-group-btn {
    display: table-cell
}

.input-group .form-control:not(:first-child):not(:last-child),
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child) {
    border-radius: 0
}

.input-group-addon,
.input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px
}

.input-group-addon.input-sm {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px
}

.input-group-addon.input-lg {
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 6px
}

.input-group-addon input[type=checkbox],
.input-group-addon input[type=radio] {
    margin-top: 0
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group>.btn,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn-group:not(:last-child)>.btn,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.input-group-addon:first-child {
    border-right: 0
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:first-child>.btn-group:not(:first-child)>.btn,
.input-group-btn:first-child>.btn:not(:first-child),
.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group>.btn,
.input-group-btn:last-child>.dropdown-toggle {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.input-group-addon:last-child {
    border-left: 0
}

.input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap
}

.input-group-btn>.btn {
    position: relative
}

.input-group-btn>.btn+.btn {
    margin-left: -1px
}

.input-group-btn>.btn:active,
.input-group-btn>.btn:focus,
.input-group-btn>.btn:hover {
    z-index: 2
}

.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group {
    margin-right: -1px
}

.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group {
    z-index: 2;
    margin-left: -1px
}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em
}

a.label:focus,
a.label:hover {
    color: #fff;
    text-decoration: none;
    cursor: pointer
}

.label:empty {
    display: none
}

.btn .label {
    position: relative;
    top: -1px
}

.label-default {
    background-color: #777
}

.label-default[href]:focus,
.label-default[href]:hover {
    background-color: #5e5e5e
}

.label-primary {
    background-color: #337ab7
}

.label-primary[href]:focus,
.label-primary[href]:hover {
    background-color: #286090
}

.label-success {
    background-color: #5cb85c
}

.label-success[href]:focus,
.label-success[href]:hover {
    background-color: #449d44
}

.label-info {
    background-color: #5bc0de
}

.label-info[href]:focus,
.label-info[href]:hover {
    background-color: #31b0d5
}

.label-warning {
    background-color: #f0ad4e
}

.label-warning[href]:focus,
.label-warning[href]:hover {
    background-color: #ec971f
}

.label-danger {
    background-color: #d9534f
}

.label-danger[href]:focus,
.label-danger[href]:hover {
    background-color: #c9302c
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px
}

.alert h4 {
    margin-top: 0;
    color: inherit
}

.alert .alert-link {
    font-weight: 700
}

.alert>p,
.alert>ul {
    margin-bottom: 0
}

.alert>p+p {
    margin-top: 5px
}

.alert-dismissable,
.alert-dismissible {
    padding-right: 35px
}

.alert-dismissable .close,
.alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d
}

.alert-success hr {
    border-top-color: #c9e2b3
}

.alert-success .alert-link {
    color: #2b542c
}

.alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f
}

.alert-info hr {
    border-top-color: #a6e1ec
}

.alert-info .alert-link {
    color: #245269
}

.alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b
}

.alert-warning hr {
    border-top-color: #f7e1b5
}

.alert-warning .alert-link {
    color: #66512c
}

.alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442
}

.alert-danger hr {
    border-top-color: #e4b9c0
}

.alert-danger .alert-link {
    color: #843534
}

.media {
    margin-top: 15px
}

.media:first-child {
    margin-top: 0
}

.media,
.media-body {
    zoom: 1;
    overflow: hidden
}

.media-body {
    width: 10000px
}

.media-object {
    display: block
}

.media-object.img-thumbnail {
    max-width: none
}

.media-right,
.media>.pull-right {
    padding-left: 10px
}

.media-left,
.media>.pull-left {
    padding-right: 10px
}

.media-body,
.media-left,
.media-right {
    display: table-cell;
    vertical-align: top
}

.media-middle {
    vertical-align: middle
}

.media-bottom {
    vertical-align: bottom
}

.media-heading {
    margin-top: 0;
    margin-bottom: 5px
}

.media-list {
    padding-left: 0;
    list-style: none
}

.list-group {
    margin-bottom: 20px;
    padding-left: 0
}

.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd
}

.list-group-item:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

a.list-group-item,
button.list-group-item {
    color: #555
}

a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
    color: #333
}

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
    text-decoration: none;
    color: #555;
    background-color: #f5f5f5
}

button.list-group-item {
    width: 100%;
    text-align: left
}

.list-group-item.disabled,
.list-group-item.disabled:focus,
.list-group-item.disabled:hover {
    background-color: #eee;
    color: #777;
    cursor: not-allowed
}

.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading {
    color: inherit
}

.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text {
    color: #777
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
    z-index: 2;
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7
}

.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading>.small,
.list-group-item.active .list-group-item-heading>small,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading>.small,
.list-group-item.active:focus .list-group-item-heading>small,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading>.small,
.list-group-item.active:hover .list-group-item-heading>small {
    color: inherit
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
    color: #c7ddef
}

.list-group-item-success {
    color: #3c763d;
    background-color: #dff0d8
}

a.list-group-item-success,
button.list-group-item-success {
    color: #3c763d
}

a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
    color: inherit
}

a.list-group-item-success:focus,
a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
    color: #3c763d;
    background-color: #d0e9c6
}

a.list-group-item-success.active,
a.list-group-item-success.active:focus,
a.list-group-item-success.active:hover,
button.list-group-item-success.active,
button.list-group-item-success.active:focus,
button.list-group-item-success.active:hover {
    color: #fff;
    background-color: #3c763d;
    border-color: #3c763d
}

.list-group-item-info {
    color: #31708f;
    background-color: #d9edf7
}

a.list-group-item-info,
button.list-group-item-info {
    color: #31708f
}

a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
    color: inherit
}

a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
    color: #31708f;
    background-color: #c4e3f3
}

a.list-group-item-info.active,
a.list-group-item-info.active:focus,
a.list-group-item-info.active:hover,
button.list-group-item-info.active,
button.list-group-item-info.active:focus,
button.list-group-item-info.active:hover {
    color: #fff;
    background-color: #31708f;
    border-color: #31708f
}

.list-group-item-warning {
    color: #8a6d3b;
    background-color: #fcf8e3
}

a.list-group-item-warning,
button.list-group-item-warning {
    color: #8a6d3b
}

a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
    color: inherit
}

a.list-group-item-warning:focus,
a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
    color: #8a6d3b;
    background-color: #faf2cc
}

a.list-group-item-warning.active,
a.list-group-item-warning.active:focus,
a.list-group-item-warning.active:hover,
button.list-group-item-warning.active,
button.list-group-item-warning.active:focus,
button.list-group-item-warning.active:hover {
    color: #fff;
    background-color: #8a6d3b;
    border-color: #8a6d3b
}

.list-group-item-danger {
    color: #a94442;
    background-color: #f2dede
}

a.list-group-item-danger,
button.list-group-item-danger {
    color: #a94442
}

a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
    color: inherit
}

a.list-group-item-danger:focus,
a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
    color: #a94442;
    background-color: #ebcccc
}

a.list-group-item-danger.active,
a.list-group-item-danger.active:focus,
a.list-group-item-danger.active:hover,
button.list-group-item-danger.active,
button.list-group-item-danger.active:focus,
button.list-group-item-danger.active:hover {
    color: #fff;
    background-color: #a94442;
    border-color: #a94442
}

.list-group-item-heading {
    margin-top: 0;
    margin-bottom: 5px
}

.list-group-item-text {
    margin-bottom: 0;
    line-height: 1.3
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0
}

.embed-responsive-16by9 {
    padding-bottom: 56.25%
}

.embed-responsive-4by3 {
    padding-bottom: 75%
}

.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2
}

.close:focus,
.close:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: .5
}

button.close {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none
}

.modal-open {
    overflow: hidden
}

.modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0
}

.modal.fade .modal-dialog {
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px
}

.modal-content {
    position: relative;
    background-color: #fff;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    background-clip: padding-box;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.in {
    opacity: .5
}

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5
}

.modal-header .close {
    margin-top: -2px
}

.modal-title {
    margin: 0;
    line-height: 1.42857143
}

.modal-body {
    position: relative;
    padding: 15px
}

.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5
}

.modal-footer .btn+.btn {
    margin-left: 5px;
    margin-bottom: 0
}

.modal-footer .btn-group .btn+.btn {
    margin-left: -1px
}

.modal-footer .btn-block+.btn-block {
    margin-left: 0
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width:768px) {
    .modal-dialog {
        width: 600px;
        margin: 30px auto
    }

    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
        box-shadow: 0 5px 15px rgba(0, 0, 0, .5)
    }

    .modal-sm {
        width: 300px
    }
}

@media (min-width:992px) {
    .modal-lg {
        width: 900px
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 12px;
    opacity: 0
}

.tooltip.in {
    opacity: .9
}

.tooltip.top {
    margin-top: -3px;
    padding: 5px 0
}

.tooltip.right {
    margin-left: 3px;
    padding: 0 5px
}

.tooltip.bottom {
    margin-top: 3px;
    padding: 5px 0
}

.tooltip.left {
    margin-left: -3px;
    padding: 0 5px
}

.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 4px
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000
}

.tooltip.top-left .tooltip-arrow {
    bottom: 0;
    right: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000
}

.tooltip.top-right .tooltip-arrow {
    bottom: 0;
    left: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000
}

.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000
}

.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #000
}

.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000
}

.tooltip.bottom-left .tooltip-arrow {
    top: 0;
    right: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000
}

.tooltip.bottom-right .tooltip-arrow {
    top: 0;
    left: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    max-width: 276px;
    padding: 1px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 14px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2)
}

.popover.top {
    margin-top: -10px
}

.popover.right {
    margin-left: 10px
}

.popover.bottom {
    margin-top: 10px
}

.popover.left {
    margin-left: -10px
}

.popover-title {
    margin: 0;
    padding: 8px 14px;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0
}

.popover-content {
    padding: 9px 14px
}

.popover>.arrow,
.popover>.arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.popover>.arrow {
    border-width: 11px
}

.popover>.arrow:after {
    border-width: 10px;
    content: ""
}

.popover.top>.arrow {
    left: 50%;
    margin-left: -11px;
    border-bottom-width: 0;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, .25);
    bottom: -11px
}

.popover.top>.arrow:after {
    content: " ";
    bottom: 1px;
    margin-left: -10px;
    border-bottom-width: 0;
    border-top-color: #fff
}

.popover.right>.arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-left-width: 0;
    border-right-color: #999;
    border-right-color: rgba(0, 0, 0, .25)
}

.popover.right>.arrow:after {
    content: " ";
    left: 1px;
    bottom: -10px;
    border-left-width: 0;
    border-right-color: #fff
}

.popover.bottom>.arrow {
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, .25);
    top: -11px
}

.popover.bottom>.arrow:after {
    content: " ";
    top: 1px;
    margin-left: -10px;
    border-top-width: 0;
    border-bottom-color: #fff
}

.popover.left>.arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0, 0, 0, .25)
}

.popover.left>.arrow:after {
    content: " ";
    right: 1px;
    border-right-width: 0;
    border-left-color: #fff;
    bottom: -10px
}

.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.row:after,
.row:before {
    content: " ";
    display: table
}

.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.modal-header:after,
.row:after {
    clear: both
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.pull-right {
    float: right !important
}

.pull-left {
    float: left !important
}

.hide {
    display: none !important
}

.show {
    display: block !important
}

.invisible {
    visibility: hidden
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.hidden {
    display: none !important
}

.affix {
    position: fixed
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://konfigurator.calosol.de/assets/work-sans-v18-latin-regular.woff2) format('woff2'), url(https://konfigurator.calosol.de/assets/work-sans-v18-latin-regular.woff) format('woff')
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    src: local(''), url(https://konfigurator.calosol.de/assets/work-sans-v18-latin-700.woff2) format('woff2'), url(https://konfigurator.calosol.de/assets/work-sans-v18-latin-700.woff) format('woff')
}

body {
    background-color: #fff;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.42857143;
    font-family: 'Work Sans', Helvetica, Arial, sans-serif;
    color: #0d0200;
    margin: 0
}

@media (max-width:1023px) {
    body {
        font-size: 15px
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Work Sans', Helvetica, Arial, sans-serif;
    font-weight: 700;
    line-height: 1.42857143;
    color: #0d0200;
    margin-top: 0;
    margin-bottom: 1em
}

.h1,
h1 {
    font-size: 36px;
    text-transform: uppercase
}

@media (max-width:1579px) {

    .h1,
    h1 {
        font-size: 30px
    }
}

@media (max-width:1249px) {

    .h1,
    h1 {
        font-size: 27px
    }
}

@media (max-width:1023px) {

    .h1,
    h1 {
        font-size: 24px
    }
}

@media (max-width:767px) {

    .h1,
    h1 {
        font-size: 22px
    }
}

.h2,
h2 {
    font-size: 22px;
    line-height: 1.1
}

@media (max-width:1249px) {

    .h2,
    h2 {
        font-size: 20px
    }
}

@media (max-width:767px) {

    .h2,
    h2 {
        font-size: 18px
    }
}

.h3,
h3 {
    font-size: 20px
}

@media (max-width:1249px) {

    .h3,
    h3 {
        font-size: 20px
    }
}

@media (max-width:480px) {

    .h3,
    h3 {
        font-size: 18px
    }
}

a {
    color: #ff9027;
    text-decoration: none;
    cursor: pointer
}

a:active,
a:focus,
a:hover {
    color: #ff4219;
    text-decoration: none;
    outline: 0
}

:focus {
    outline: 0
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

.text-justify {
    text-align: justify
}

.text-nowrap {
    white-space: nowrap
}

.text-lowercase {
    text-transform: lowercase
}

.text-uppercase {
    text-transform: uppercase
}

.text-capitalize {
    text-transform: capitalize
}

ol,
ul {
    margin: 0
}

ol li,
ul li {
    color: #0d0200;
    padding: 5px 0
}

address {
    margin-bottom: 24px;
    font-style: italic;
    line-height: 1.42857143
}

hr {
    border-top: 1px solid #ccc;
    margin-top: 100px;
    margin-bottom: 100px
}

label {
    font-weight: 400;
    color: #999
}

.linkWithIcon {
    position: relative;
    padding-left: 30px
}

.linkWithIcon .icon {
    position: absolute;
    left: 0;
    top: 50%;
    line-height: 20px;
    margin-top: -10px;
    width: 25px;
    font-size: 18px;
    text-align: center
}

.color-white {
    color: #fff
}

.color-black {
    color: #0d0200
}

.color-gray-base {
    color: #999
}

.color-middle-gray {
    color: #ccc
}

.color-light-gray {
    color: #eee
}

.color-primary {
    color: #ff9027
}

.color-second {
    color: #ff4219
}

.color-action {
    color: #ff4219
}

.color-danger {
    color: #ed1800
}

.color-warning {
    color: #e6e600
}

.color-success {
    color: #99c137
}

.color-info {
    color: #999
}

.bg-white {
    background-color: #fff
}

.bg-black {
    background-color: #0d0200
}

.bg-gray-base {
    background-color: #999
}

.bg-middle-gray {
    background-color: #ccc
}

.bg-light-gray {
    background-color: #eee
}

.bg-primary {
    background-color: #ff9027
}

.bg-second {
    background-color: #ff4219
}

.bg-action {
    background-color: #ff4219
}

.bg-danger {
    background-color: #ed1800
}

.bg-warning {
    background-color: #e6e600
}

.bg-success {
    background-color: #99c137
}

.bg-info {
    background-color: #999
}

.border-white {
    border: 1px solid #fff
}

.border-black {
    border: 1px solid #0d0200
}

.border-gray-base {
    border: 1px solid #999
}

.border-middle-gray {
    border: 1px solid #ccc
}

.border-light-gray {
    border: 1px solid #eee
}

.border-primary {
    border: 1px solid #ff9027
}

.border-second {
    border: 1px solid #ff4219
}

.border-action {
    border: 1px solid #ff4219
}

.border-danger {
    border: 1px solid #ed1800
}

.border-warning {
    border: 1px solid #e6e600
}

.border-success {
    border: 1px solid #99c137
}

.border-info {
    border: 1px solid #999
}

.bg-orange-gradient {
    background: #ff9027;
    background: linear-gradient(114deg, #ff9027 0, #ff9027 30%, #ff9325 50%, #ffb300 100%)
}

.bg-orange-gradient .h1,
.bg-orange-gradient .h2,
.bg-orange-gradient h1,
.bg-orange-gradient h2 {
    color: #fff
}

.bg-orange-gradient a {
    color: #0d0200
}

.bg-orange-gradient a:focus,
.bg-orange-gradient a:hover {
    color: #fff
}

.bg-light-orange {
    background-color: #ffebc2
}

.margin {
    margin: 10px
}

.marginTop {
    margin-top: 10px
}

.marginRight {
    margin-right: 10px
}

.marginBottom {
    margin-bottom: 10px
}

.marginLeft {
    margin-left: 10px
}

.margin--xsmall {
    margin: 5px
}

.marginTop--xsmall {
    margin-top: 5px
}

.marginRight--xsmall {
    margin-right: 5px
}

.marginBottom--xsmall {
    margin-bottom: 5px
}

.marginLeft--xsmall {
    margin-left: 5px
}

.margin--xxlarge {
    margin: 400px
}

.marginTop--xxlarge {
    margin-top: 400px
}

.marginRight--xxlarge {
    margin-right: 400px
}

.marginBottom--xxlarge {
    margin-bottom: 400px
}

.marginLeft--xxlarge {
    margin-left: 400px
}

.margin--xlarge {
    margin: 200px
}

.marginTop--xlarge {
    margin-top: 200px
}

.marginRight--xlarge {
    margin-right: 200px
}

.marginBottom--xlarge {
    margin-bottom: 200px
}

.marginLeft--xlarge {
    margin-left: 200px
}

.margin--large {
    margin: 100px
}

.marginTop--large {
    margin-top: 100px
}

.marginRight--large {
    margin-right: 100px
}

.marginBottom--large {
    margin-bottom: 100px
}

.marginLeft--large {
    margin-left: 100px
}

.margin--small {
    margin: 20px
}

.marginTop--small {
    margin-top: 20px
}

.marginRight--small {
    margin-right: 20px
}

.marginBottom--small {
    margin-bottom: 20px
}

.marginLeft--small {
    margin-left: 20px
}

@media (max-width:1249px) {
    .margin--large {
        margin: 60px
    }

    .marginTop--large {
        margin-top: 60px
    }

    .marginBottom--large {
        margin-bottom: 60px
    }

    .marginLeft--large {
        margin-left: 60px
    }

    .marginRight--large {
        margin-right: 60px
    }

    .margin--xlarge {
        margin: 120px
    }

    .marginTop--xlarge {
        margin-top: 120px
    }

    .marginBottom--xlarge {
        margin-bottom: 120px
    }

    .marginLeft--xlarge {
        margin-left: 120px
    }

    .marginRight--xlarge {
        margin-right: 120px
    }
}

@media (max-width:767px) {
    .margin--large {
        margin: 40px
    }

    .marginTop--large {
        margin-top: 40px
    }

    .marginBottom--large {
        margin-bottom: 40px
    }

    .marginLeft--large {
        margin-left: 40px
    }

    .marginRight--large {
        margin-right: 40px
    }

    .margin--xlarge {
        margin: 80px
    }

    .marginTop--xlarge {
        margin-top: 80px
    }

    .marginBottom--xlarge {
        margin-bottom: 80px
    }

    .marginLeft--xlarge {
        margin-left: 80px
    }

    .marginRight--xlarge {
        margin-right: 80px
    }
}

@media (max-width:480px) {
    .margin--large {
        margin: 40px
    }

    .marginTop--large {
        margin-top: 40px
    }

    .marginBottom--large {
        margin-bottom: 40px
    }

    .marginLeft--large {
        margin-left: 40px
    }

    .marginRight--large {
        margin-right: 40px
    }

    .margin--xlarge {
        margin: 60px
    }

    .marginTop--xlarge {
        margin-top: 60px
    }

    .marginBottom--xlarge {
        margin-bottom: 60px
    }

    .marginLeft--xlarge {
        margin-left: 60px
    }

    .marginRight--xlarge {
        margin-right: 60px
    }
}

.padding {
    padding: 10px
}

.paddingTop {
    padding-top: 10px
}

.paddingRight {
    padding-right: 10px
}

.paddingBottom {
    padding-bottom: 10px
}

.paddingLeft {
    padding-left: 10px
}

.padding--xsmall {
    padding: 5px
}

.paddingTop--xsmall {
    padding-top: 5px
}

.paddingRight--xsmall {
    padding-right: 5px
}

.paddingBottom--xsmall {
    padding-bottom: 5px
}

.paddingLeft--xsmall {
    padding-left: 5px
}

.padding--xxlarge {
    padding: 400px
}

.paddingTop--xxlarge {
    padding-top: 400px
}

.paddingRight--xxlarge {
    padding-right: 400px
}

.paddingBottom--xxlarge {
    padding-bottom: 400px
}

.paddingLeft--xxlarge {
    padding-left: 400px
}

.padding--xlarge {
    padding: 200px
}

.paddingTop--xlarge {
    padding-top: 200px
}

.paddingRight--xlarge {
    padding-right: 200px
}

.paddingBottom--xlarge {
    padding-bottom: 200px
}

.paddingLeft--xlarge {
    padding-left: 200px
}

.padding--large {
    padding: 100px
}

.paddingTop--large {
    padding-top: 100px
}

.paddingRight--large {
    padding-right: 100px
}

.paddingBottom--large {
    padding-bottom: 100px
}

.paddingLeft--large {
    padding-left: 100px
}

.padding--small {
    padding: 20px
}

.paddingTop--small {
    padding-top: 20px
}

.paddingRight--small {
    padding-right: 20px
}

.paddingBottom--small {
    padding-bottom: 20px
}

.paddingLeft--small {
    padding-left: 20px
}

.padding--large {
    padding: 80px
}

@media (max-width:1249px) {
    .padding--large {
        padding: 60px
    }

    .paddingTop--large {
        padding-top: 60px
    }

    .paddingBottom--large {
        padding-bottom: 60px
    }

    .paddingLeft--large {
        padding-left: 60px
    }

    .paddingRight--large {
        padding-right: 60px
    }

    .padding--xlarge {
        padding: 120px
    }

    .paddingTop--xlarge {
        padding-top: 120px
    }

    .paddingBottom--xlarge {
        padding-bottom: 120px
    }

    .paddingLeft--xlarge {
        padding-left: 120px
    }

    .paddingRight--xlarge {
        padding-right: 120px
    }

    .padding--xxlarge {
        padding: 240px
    }

    .paddingTop--xxlarge {
        padding-top: 240px
    }

    .paddingBottom--xxlarge {
        padding-bottom: 240px
    }

    .paddingLeft--xxlarge {
        padding-left: 240px
    }

    .paddingRight--xxlarge {
        padding-right: 240px
    }
}

@media (max-width:767px) {
    .padding--large {
        padding: 40px
    }

    .paddingTop--large {
        padding-top: 40px
    }

    .paddingBottom--large {
        padding-bottom: 40px
    }

    .paddingLeft--large {
        padding-left: 40px
    }

    .paddingRight--large {
        padding-right: 40px
    }

    .padding--xlarge {
        padding: 80px
    }

    .paddingTop--xlarge {
        padding-top: 80px
    }

    .paddingBottom--xlarge {
        padding-bottom: 80px
    }

    .paddingLeft--xlarge {
        padding-left: 80px
    }

    .paddingRight--xlarge {
        padding-right: 80px
    }

    .padding--xxlarge {
        padding: 190px
    }

    .paddingTop--xxlarge {
        padding-top: 190px
    }

    .paddingBottom--xxlarge {
        padding-bottom: 190px
    }

    .paddingLeft--xxlarge {
        padding-left: 190px
    }

    .paddingRight--xxlarge {
        padding-right: 190px
    }
}

@media (max-width:480px) {
    .padding--large {
        padding: 30px
    }

    .paddingTop--large {
        padding-top: 30px
    }

    .paddingBottom--large {
        padding-bottom: 30px
    }

    .paddingLeft--large {
        padding-left: 30px
    }

    .paddingRight--large {
        padding-right: 30px
    }

    .padding--xlarge {
        padding: 60px
    }

    .paddingTop--xlarge {
        padding-top: 60px
    }

    .paddingBottom--xlarge {
        padding-bottom: 60px
    }

    .paddingLeft--xlarge {
        padding-left: 60px
    }

    .paddingRight--xlarge {
        padding-right: 60px
    }
}

@font-face {
    font-family: icons-new2;
    src: url(https://konfigurator.calosol.de/assets/icons-new2.woff) format('woff'), url(https://konfigurator.calosol.de/assets/icons-new2.woff2) format('woff2')
}

.icon-001-mail:before,
.icon-002-phone:before,
.icon-003-symbol:before,
.icon-004-homeenergie:before,
.icon-005-save:before,
.icon-006-solar:before,
.icon-007-friendship:before,
.icon-008-renewable:before,
.icon-009-energy:before,
.icon-010-solar2:before,
.icon-011-sun:before,
.icon-012-one:before,
.icon-013-two:before,
.icon-014-three:before,
.icon-015-four:before,
.icon-016-six:before,
.icon-017-seven:before,
.icon-018-eight:before,
.icon-019-nine:before,
.icon-020-five:before,
.icon-023-doublearrow:before,
.icon-024-clock:before,
.icon-025-homeoffice:before,
.icon-026-invoice:before,
.icon-027-away:before,
.icon-028-map:before,
.icon-029-check:before,
.icon-030-angel-down:before,
.icon-031-error:before,
.icon-base-pseudo {
    font-family: icons-new2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-decoration: none;
    text-transform: none
}

.icon-001-mail:before {
    content: "\E001"
}

.icon-002-phone:before {
    content: "\E002"
}

.icon-003-symbol:before {
    content: "\E003"
}

.icon-004-homeenergie:before {
    content: "\E004"
}

.icon-005-save:before {
    content: "\E005"
}

.icon-006-solar:before {
    content: "\E006"
}

.icon-007-friendship:before {
    content: "\E007"
}

.icon-008-renewable:before {
    content: "\E008"
}

.icon-009-energy:before {
    content: "\E009"
}

.icon-010-solar2:before {
    content: "\E00A"
}

.icon-011-sun:before {
    content: "\E00B"
}

.icon-012-one:before {
    content: "\E00C"
}

.icon-013-two:before {
    content: "\E00D"
}

.icon-014-three:before {
    content: "\E00E"
}

.icon-015-four:before {
    content: "\E00F"
}

.icon-016-six:before {
    content: "\E010"
}

.icon-017-seven:before {
    content: "\E011"
}

.icon-018-eight:before {
    content: "\E012"
}

.icon-019-nine:before {
    content: "\E013"
}

.icon-020-five:before {
    content: "\E014"
}

.icon-023-doublearrow:before {
    content: "\E015"
}

.icon-024-clock:before {
    content: "\E016"
}

.icon-025-homeoffice:before {
    content: "\E017"
}

.icon-026-invoice:before {
    content: "\E018"
}

.icon-027-away:before {
    content: "\E019"
}

.icon-028-map:before {
    content: "\E01A"
}

.icon-029-check:before {
    content: "\E01B"
}

.icon-030-angel-down:before {
    content: "\E01C"
}

.icon-031-error:before {
    content: "\E01D"
}

.alert {
    border-radius: 4px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    position: relative;
    padding-left: 50px
}

.alert:before {
    content: "\E01D";
    font-family: icons-new2;
    position: absolute;
    left: 15px;
    top: 50%;
    font-size: 30px;
    margin-top: -21px
}

.alert-danger {
    color: #ed1800;
    border-color: #ed1800;
    background-color: #ffefed
}

.alert-warning {
    color: #e6e600;
    border-color: #e6e600;
    background-color: #ffffe6
}

.alert-success {
    color: #99c137;
    border-color: #99c137;
    background-color: #fcfdfa
}

.alert-info {
    color: #999;
    border-color: #999;
    background-color: #fff
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    max-width: 1580px
}

.container--small {
    max-width: 1024px
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 30px;
    padding-right: 30px
}

.container-max {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto
}

.row {
    margin-left: -30px;
    margin-right: -30px
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xlg-1,
.col-xlg-10,
.col-xlg-11,
.col-xlg-12,
.col-xlg-2,
.col-xlg-3,
.col-xlg-4,
.col-xlg-5,
.col-xlg-6,
.col-xlg-7,
.col-xlg-8,
.col-xlg-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-left: 30px;
    padding-right: 30px
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    float: left
}

.col-xs-12 {
    width: 100%
}

.col-xs-11 {
    width: 91.66666667%
}

.col-xs-10 {
    width: 83.33333333%
}

.col-xs-9 {
    width: 75%
}

.col-xs-8 {
    width: 66.66666667%
}

.col-xs-7 {
    width: 58.33333333%
}

.col-xs-6 {
    width: 50%
}

.col-xs-5 {
    width: 41.66666667%
}

.col-xs-4 {
    width: 33.33333333%
}

.col-xs-3 {
    width: 25%
}

.col-xs-2 {
    width: 16.66666667%
}

.col-xs-1 {
    width: 8.33333333%
}

.col-xs-pull-12 {
    right: 100%
}

.col-xs-pull-11 {
    right: 91.66666667%
}

.col-xs-pull-10 {
    right: 83.33333333%
}

.col-xs-pull-9 {
    right: 75%
}

.col-xs-pull-8 {
    right: 66.66666667%
}

.col-xs-pull-7 {
    right: 58.33333333%
}

.col-xs-pull-6 {
    right: 50%
}

.col-xs-pull-5 {
    right: 41.66666667%
}

.col-xs-pull-4 {
    right: 33.33333333%
}

.col-xs-pull-3 {
    right: 25%
}

.col-xs-pull-2 {
    right: 16.66666667%
}

.col-xs-pull-1 {
    right: 8.33333333%
}

.col-xs-pull-0 {
    right: auto
}

.col-xs-push-12 {
    left: 100%
}

.col-xs-push-11 {
    left: 91.66666667%
}

.col-xs-push-10 {
    left: 83.33333333%
}

.col-xs-push-9 {
    left: 75%
}

.col-xs-push-8 {
    left: 66.66666667%
}

.col-xs-push-7 {
    left: 58.33333333%
}

.col-xs-push-6 {
    left: 50%
}

.col-xs-push-5 {
    left: 41.66666667%
}

.col-xs-push-4 {
    left: 33.33333333%
}

.col-xs-push-3 {
    left: 25%
}

.col-xs-push-2 {
    left: 16.66666667%
}

.col-xs-push-1 {
    left: 8.33333333%
}

.col-xs-push-0 {
    left: auto
}

.col-xs-offset-12 {
    margin-left: 100%
}

.col-xs-offset-11 {
    margin-left: 91.66666667%
}

.col-xs-offset-10 {
    margin-left: 83.33333333%
}

.col-xs-offset-9 {
    margin-left: 75%
}

.col-xs-offset-8 {
    margin-left: 66.66666667%
}

.col-xs-offset-7 {
    margin-left: 58.33333333%
}

.col-xs-offset-6 {
    margin-left: 50%
}

.col-xs-offset-5 {
    margin-left: 41.66666667%
}

.col-xs-offset-4 {
    margin-left: 33.33333333%
}

.col-xs-offset-3 {
    margin-left: 25%
}

.col-xs-offset-2 {
    margin-left: 16.66666667%
}

.col-xs-offset-1 {
    margin-left: 8.33333333%
}

.col-xs-offset-0 {
    margin-left: 0
}

@media (min-width:768px) {

    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9 {
        float: left
    }

    .col-sm-12 {
        width: 100%
    }

    .col-sm-11 {
        width: 91.66666667%
    }

    .col-sm-10 {
        width: 83.33333333%
    }

    .col-sm-9 {
        width: 75%
    }

    .col-sm-8 {
        width: 66.66666667%
    }

    .col-sm-7 {
        width: 58.33333333%
    }

    .col-sm-6 {
        width: 50%
    }

    .col-sm-5 {
        width: 41.66666667%
    }

    .col-sm-4 {
        width: 33.33333333%
    }

    .col-sm-3 {
        width: 25%
    }

    .col-sm-2 {
        width: 16.66666667%
    }

    .col-sm-1 {
        width: 8.33333333%
    }

    .col-sm-pull-12 {
        right: 100%
    }

    .col-sm-pull-11 {
        right: 91.66666667%
    }

    .col-sm-pull-10 {
        right: 83.33333333%
    }

    .col-sm-pull-9 {
        right: 75%
    }

    .col-sm-pull-8 {
        right: 66.66666667%
    }

    .col-sm-pull-7 {
        right: 58.33333333%
    }

    .col-sm-pull-6 {
        right: 50%
    }

    .col-sm-pull-5 {
        right: 41.66666667%
    }

    .col-sm-pull-4 {
        right: 33.33333333%
    }

    .col-sm-pull-3 {
        right: 25%
    }

    .col-sm-pull-2 {
        right: 16.66666667%
    }

    .col-sm-pull-1 {
        right: 8.33333333%
    }

    .col-sm-pull-0 {
        right: auto
    }

    .col-sm-push-12 {
        left: 100%
    }

    .col-sm-push-11 {
        left: 91.66666667%
    }

    .col-sm-push-10 {
        left: 83.33333333%
    }

    .col-sm-push-9 {
        left: 75%
    }

    .col-sm-push-8 {
        left: 66.66666667%
    }

    .col-sm-push-7 {
        left: 58.33333333%
    }

    .col-sm-push-6 {
        left: 50%
    }

    .col-sm-push-5 {
        left: 41.66666667%
    }

    .col-sm-push-4 {
        left: 33.33333333%
    }

    .col-sm-push-3 {
        left: 25%
    }

    .col-sm-push-2 {
        left: 16.66666667%
    }

    .col-sm-push-1 {
        left: 8.33333333%
    }

    .col-sm-push-0 {
        left: auto
    }

    .col-sm-offset-12 {
        margin-left: 100%
    }

    .col-sm-offset-11 {
        margin-left: 91.66666667%
    }

    .col-sm-offset-10 {
        margin-left: 83.33333333%
    }

    .col-sm-offset-9 {
        margin-left: 75%
    }

    .col-sm-offset-8 {
        margin-left: 66.66666667%
    }

    .col-sm-offset-7 {
        margin-left: 58.33333333%
    }

    .col-sm-offset-6 {
        margin-left: 50%
    }

    .col-sm-offset-5 {
        margin-left: 41.66666667%
    }

    .col-sm-offset-4 {
        margin-left: 33.33333333%
    }

    .col-sm-offset-3 {
        margin-left: 25%
    }

    .col-sm-offset-2 {
        margin-left: 16.66666667%
    }

    .col-sm-offset-1 {
        margin-left: 8.33333333%
    }

    .col-sm-offset-0 {
        margin-left: 0
    }
}

@media (min-width:1024px) {

    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9 {
        float: left
    }

    .col-md-12 {
        width: 100%
    }

    .col-md-11 {
        width: 91.66666667%
    }

    .col-md-10 {
        width: 83.33333333%
    }

    .col-md-9 {
        width: 75%
    }

    .col-md-8 {
        width: 66.66666667%
    }

    .col-md-7 {
        width: 58.33333333%
    }

    .col-md-6 {
        width: 50%
    }

    .col-md-5 {
        width: 41.66666667%
    }

    .col-md-4 {
        width: 33.33333333%
    }

    .col-md-3 {
        width: 25%
    }

    .col-md-2 {
        width: 16.66666667%
    }

    .col-md-1 {
        width: 8.33333333%
    }

    .col-md-pull-12 {
        right: 100%
    }

    .col-md-pull-11 {
        right: 91.66666667%
    }

    .col-md-pull-10 {
        right: 83.33333333%
    }

    .col-md-pull-9 {
        right: 75%
    }

    .col-md-pull-8 {
        right: 66.66666667%
    }

    .col-md-pull-7 {
        right: 58.33333333%
    }

    .col-md-pull-6 {
        right: 50%
    }

    .col-md-pull-5 {
        right: 41.66666667%
    }

    .col-md-pull-4 {
        right: 33.33333333%
    }

    .col-md-pull-3 {
        right: 25%
    }

    .col-md-pull-2 {
        right: 16.66666667%
    }

    .col-md-pull-1 {
        right: 8.33333333%
    }

    .col-md-pull-0 {
        right: auto
    }

    .col-md-push-12 {
        left: 100%
    }

    .col-md-push-11 {
        left: 91.66666667%
    }

    .col-md-push-10 {
        left: 83.33333333%
    }

    .col-md-push-9 {
        left: 75%
    }

    .col-md-push-8 {
        left: 66.66666667%
    }

    .col-md-push-7 {
        left: 58.33333333%
    }

    .col-md-push-6 {
        left: 50%
    }

    .col-md-push-5 {
        left: 41.66666667%
    }

    .col-md-push-4 {
        left: 33.33333333%
    }

    .col-md-push-3 {
        left: 25%
    }

    .col-md-push-2 {
        left: 16.66666667%
    }

    .col-md-push-1 {
        left: 8.33333333%
    }

    .col-md-push-0 {
        left: auto
    }

    .col-md-offset-12 {
        margin-left: 100%
    }

    .col-md-offset-11 {
        margin-left: 91.66666667%
    }

    .col-md-offset-10 {
        margin-left: 83.33333333%
    }

    .col-md-offset-9 {
        margin-left: 75%
    }

    .col-md-offset-8 {
        margin-left: 66.66666667%
    }

    .col-md-offset-7 {
        margin-left: 58.33333333%
    }

    .col-md-offset-6 {
        margin-left: 50%
    }

    .col-md-offset-5 {
        margin-left: 41.66666667%
    }

    .col-md-offset-4 {
        margin-left: 33.33333333%
    }

    .col-md-offset-3 {
        margin-left: 25%
    }

    .col-md-offset-2 {
        margin-left: 16.66666667%
    }

    .col-md-offset-1 {
        margin-left: 8.33333333%
    }

    .col-md-offset-0 {
        margin-left: 0
    }
}

@media (min-width:1250px) {

    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9 {
        float: left
    }

    .col-lg-12 {
        width: 100%
    }

    .col-lg-11 {
        width: 91.66666667%
    }

    .col-lg-10 {
        width: 83.33333333%
    }

    .col-lg-9 {
        width: 75%
    }

    .col-lg-8 {
        width: 66.66666667%
    }

    .col-lg-7 {
        width: 58.33333333%
    }

    .col-lg-6 {
        width: 50%
    }

    .col-lg-5 {
        width: 41.66666667%
    }

    .col-lg-4 {
        width: 33.33333333%
    }

    .col-lg-3 {
        width: 25%
    }

    .col-lg-2 {
        width: 16.66666667%
    }

    .col-lg-1 {
        width: 8.33333333%
    }

    .col-lg-pull-12 {
        right: 100%
    }

    .col-lg-pull-11 {
        right: 91.66666667%
    }

    .col-lg-pull-10 {
        right: 83.33333333%
    }

    .col-lg-pull-9 {
        right: 75%
    }

    .col-lg-pull-8 {
        right: 66.66666667%
    }

    .col-lg-pull-7 {
        right: 58.33333333%
    }

    .col-lg-pull-6 {
        right: 50%
    }

    .col-lg-pull-5 {
        right: 41.66666667%
    }

    .col-lg-pull-4 {
        right: 33.33333333%
    }

    .col-lg-pull-3 {
        right: 25%
    }

    .col-lg-pull-2 {
        right: 16.66666667%
    }

    .col-lg-pull-1 {
        right: 8.33333333%
    }

    .col-lg-pull-0 {
        right: auto
    }

    .col-lg-push-12 {
        left: 100%
    }

    .col-lg-push-11 {
        left: 91.66666667%
    }

    .col-lg-push-10 {
        left: 83.33333333%
    }

    .col-lg-push-9 {
        left: 75%
    }

    .col-lg-push-8 {
        left: 66.66666667%
    }

    .col-lg-push-7 {
        left: 58.33333333%
    }

    .col-lg-push-6 {
        left: 50%
    }

    .col-lg-push-5 {
        left: 41.66666667%
    }

    .col-lg-push-4 {
        left: 33.33333333%
    }

    .col-lg-push-3 {
        left: 25%
    }

    .col-lg-push-2 {
        left: 16.66666667%
    }

    .col-lg-push-1 {
        left: 8.33333333%
    }

    .col-lg-push-0 {
        left: auto
    }

    .col-lg-offset-12 {
        margin-left: 100%
    }

    .col-lg-offset-11 {
        margin-left: 91.66666667%
    }

    .col-lg-offset-10 {
        margin-left: 83.33333333%
    }

    .col-lg-offset-9 {
        margin-left: 75%
    }

    .col-lg-offset-8 {
        margin-left: 66.66666667%
    }

    .col-lg-offset-7 {
        margin-left: 58.33333333%
    }

    .col-lg-offset-6 {
        margin-left: 50%
    }

    .col-lg-offset-5 {
        margin-left: 41.66666667%
    }

    .col-lg-offset-4 {
        margin-left: 33.33333333%
    }

    .col-lg-offset-3 {
        margin-left: 25%
    }

    .col-lg-offset-2 {
        margin-left: 16.66666667%
    }

    .col-lg-offset-1 {
        margin-left: 8.33333333%
    }

    .col-lg-offset-0 {
        margin-left: 0
    }

    .col-lg-1-5 {
        width: 20%
    }

    .col-lg-2-5 {
        width: 40%
    }

    .col-lg-3-5 {
        width: 60%
    }

    .col-lg-4-5 {
        width: 80%
    }
}

@media (min-width:1580px) {

    .col-xlg-1,
    .col-xlg-10,
    .col-xlg-11,
    .col-xlg-12,
    .col-xlg-2,
    .col-xlg-3,
    .col-xlg-4,
    .col-xlg-5,
    .col-xlg-6,
    .col-xlg-7,
    .col-xlg-8,
    .col-xlg-9 {
        float: left
    }

    .col-xlg-12 {
        width: 100%
    }

    .col-xlg-11 {
        width: 91.66666667%
    }

    .col-xlg-10 {
        width: 83.33333333%
    }

    .col-xlg-9 {
        width: 75%
    }

    .col-xlg-8 {
        width: 66.66666667%
    }

    .col-xlg-7 {
        width: 58.33333333%
    }

    .col-xlg-6 {
        width: 50%
    }

    .col-xlg-5 {
        width: 41.66666667%
    }

    .col-xlg-4 {
        width: 33.33333333%
    }

    .col-xlg-3 {
        width: 25%
    }

    .col-xlg-2 {
        width: 16.66666667%
    }

    .col-xlg-1 {
        width: 8.33333333%
    }

    .col-xlg-pull-12 {
        right: 100%
    }

    .col-xlg-pull-11 {
        right: 91.66666667%
    }

    .col-xlg-pull-10 {
        right: 83.33333333%
    }

    .col-xlg-pull-9 {
        right: 75%
    }

    .col-xlg-pull-8 {
        right: 66.66666667%
    }

    .col-xlg-pull-7 {
        right: 58.33333333%
    }

    .col-xlg-pull-6 {
        right: 50%
    }

    .col-xlg-pull-5 {
        right: 41.66666667%
    }

    .col-xlg-pull-4 {
        right: 33.33333333%
    }

    .col-xlg-pull-3 {
        right: 25%
    }

    .col-xlg-pull-2 {
        right: 16.66666667%
    }

    .col-xlg-pull-1 {
        right: 8.33333333%
    }

    .col-xlg-pull-0 {
        right: auto
    }

    .col-xlg-push-12 {
        left: 100%
    }

    .col-xlg-push-11 {
        left: 91.66666667%
    }

    .col-xlg-push-10 {
        left: 83.33333333%
    }

    .col-xlg-push-9 {
        left: 75%
    }

    .col-xlg-push-8 {
        left: 66.66666667%
    }

    .col-xlg-push-7 {
        left: 58.33333333%
    }

    .col-xlg-push-6 {
        left: 50%
    }

    .col-xlg-push-5 {
        left: 41.66666667%
    }

    .col-xlg-push-4 {
        left: 33.33333333%
    }

    .col-xlg-push-3 {
        left: 25%
    }

    .col-xlg-push-2 {
        left: 16.66666667%
    }

    .col-xlg-push-1 {
        left: 8.33333333%
    }

    .col-xlg-push-0 {
        left: auto
    }

    .col-xlg-offset-12 {
        margin-left: 100%
    }

    .col-xlg-offset-11 {
        margin-left: 91.66666667%
    }

    .col-xlg-offset-10 {
        margin-left: 83.33333333%
    }

    .col-xlg-offset-9 {
        margin-left: 75%
    }

    .col-xlg-offset-8 {
        margin-left: 66.66666667%
    }

    .col-xlg-offset-7 {
        margin-left: 58.33333333%
    }

    .col-xlg-offset-6 {
        margin-left: 50%
    }

    .col-xlg-offset-5 {
        margin-left: 41.66666667%
    }

    .col-xlg-offset-4 {
        margin-left: 33.33333333%
    }

    .col-xlg-offset-3 {
        margin-left: 25%
    }

    .col-xlg-offset-2 {
        margin-left: 16.66666667%
    }

    .col-xlg-offset-1 {
        margin-left: 8.33333333%
    }

    .col-xlg-offset-0 {
        margin-left: 0
    }

    .col-xlg-1-5 {
        width: 20%
    }

    .col-xlg-2-5 {
        width: 40%
    }

    .col-xlg-3-5 {
        width: 60%
    }

    .col-xlg-4-5 {
        width: 80%
    }
}

@media (min-width:1580px) {
    .xlg-margin {
        margin-bottom: 20px
    }

    .xlg-margin--small {
        margin-bottom: 10px
    }
}

@media (min-width:1250px) and (max-width:1579px) {
    .lg-margin {
        margin-bottom: 20px
    }

    .lg-margin--small {
        margin-bottom: 10px
    }
}

@media (min-width:1024px) and (max-width:1249px) {
    .md-margin {
        margin-bottom: 20px
    }

    .md-margin--small {
        margin-bottom: 10px
    }
}

@media (min-width:768px) and (max-width:1023px) {
    .sm-margin {
        margin-bottom: 20px
    }

    .sm-margin--small {
        margin-bottom: 10px
    }

    .float-sm-right {
        float: right
    }
}

@media (max-width:767px) {
    .xs-margin {
        margin-bottom: 20px
    }

    .xs-margin--small {
        margin-bottom: 10px
    }

    .text-center-xs {
        text-align: center !important
    }
}

@media (max-width:480px) {

    .col-xxs-1,
    .col-xxs-10,
    .col-xxs-11,
    .col-xxs-12,
    .col-xxs-2,
    .col-xxs-3,
    .col-xxs-4,
    .col-xxs-5,
    .col-xxs-6,
    .col-xxs-7,
    .col-xxs-8,
    .col-xxs-9 {
        float: left
    }

    .col-xxs-12 {
        width: 100%
    }

    .col-xxs-11 {
        width: 91.66666667%
    }

    .col-xxs-10 {
        width: 83.33333333%
    }

    .col-xxs-9 {
        width: 75%
    }

    .col-xxs-8 {
        width: 66.66666667%
    }

    .col-xxs-7 {
        width: 58.33333333%
    }

    .col-xxs-6 {
        width: 50%
    }

    .col-xxs-5 {
        width: 41.66666667%
    }

    .col-xxs-4 {
        width: 33.33333333%
    }

    .col-xxs-3 {
        width: 25%
    }

    .col-xxs-2 {
        width: 16.66666667%
    }

    .col-xxs-1 {
        width: 8.33333333%
    }

    .col-xxs-pull-12 {
        right: 100%
    }

    .col-xxs-pull-11 {
        right: 91.66666667%
    }

    .col-xxs-pull-10 {
        right: 83.33333333%
    }

    .col-xxs-pull-9 {
        right: 75%
    }

    .col-xxs-pull-8 {
        right: 66.66666667%
    }

    .col-xxs-pull-7 {
        right: 58.33333333%
    }

    .col-xxs-pull-6 {
        right: 50%
    }

    .col-xxs-pull-5 {
        right: 41.66666667%
    }

    .col-xxs-pull-4 {
        right: 33.33333333%
    }

    .col-xxs-pull-3 {
        right: 25%
    }

    .col-xxs-pull-2 {
        right: 16.66666667%
    }

    .col-xxs-pull-1 {
        right: 8.33333333%
    }

    .col-xxs-pull-0 {
        right: auto
    }

    .col-xxs-push-12 {
        left: 100%
    }

    .col-xxs-push-11 {
        left: 91.66666667%
    }

    .col-xxs-push-10 {
        left: 83.33333333%
    }

    .col-xxs-push-9 {
        left: 75%
    }

    .col-xxs-push-8 {
        left: 66.66666667%
    }

    .col-xxs-push-7 {
        left: 58.33333333%
    }

    .col-xxs-push-6 {
        left: 50%
    }

    .col-xxs-push-5 {
        left: 41.66666667%
    }

    .col-xxs-push-4 {
        left: 33.33333333%
    }

    .col-xxs-push-3 {
        left: 25%
    }

    .col-xxs-push-2 {
        left: 16.66666667%
    }

    .col-xxs-push-1 {
        left: 8.33333333%
    }

    .col-xxs-push-0 {
        left: auto
    }

    .col-xxs-offset-12 {
        margin-left: 100%
    }

    .col-xxs-offset-11 {
        margin-left: 91.66666667%
    }

    .col-xxs-offset-10 {
        margin-left: 83.33333333%
    }

    .col-xxs-offset-9 {
        margin-left: 75%
    }

    .col-xxs-offset-8 {
        margin-left: 66.66666667%
    }

    .col-xxs-offset-7 {
        margin-left: 58.33333333%
    }

    .col-xxs-offset-6 {
        margin-left: 50%
    }

    .col-xxs-offset-5 {
        margin-left: 41.66666667%
    }

    .col-xxs-offset-4 {
        margin-left: 33.33333333%
    }

    .col-xxs-offset-3 {
        margin-left: 25%
    }

    .col-xxs-offset-2 {
        margin-left: 16.66666667%
    }

    .col-xxs-offset-1 {
        margin-left: 8.33333333%
    }

    .col-xxs-offset-0 {
        margin-left: 0
    }
}

.clearfloat {
    clear: both
}

.row.nogap {
    margin-left: 0;
    margin-right: 0
}

.row.nogap>div {
    padding-left: 0;
    padding-right: 0
}

.row.flexrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-align: stretch;
    -webkit-box-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.row.flexrow:after,
.row.flexrow:before {
    display: none
}

.row.flexrow.align-items-center {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center
}

.row.flexrow.justify-content-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.row.gridGap--small {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px
}

.row.gridGap--small>div {
    padding-left: 15px;
    padding-right: 15px
}

@-ms-viewport {
    width: device-width
}

.visible-lg,
.visible-md,
.visible-sm,
.visible-xs {
    display: none !important
}

.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block {
    display: none !important
}

@media (max-width:767px) {
    .visible-xs {
        display: block !important
    }

    table.visible-xs {
        display: table !important
    }

    tr.visible-xs {
        display: table-row !important
    }

    td.visible-xs,
    th.visible-xs {
        display: table-cell !important
    }
}

@media (max-width:767px) {
    .visible-xs-block {
        display: block !important
    }
}

@media (max-width:767px) {
    .visible-xs-inline {
        display: inline !important
    }
}

@media (max-width:767px) {
    .visible-xs-inline-block {
        display: inline-block !important
    }
}

@media (min-width:768px) and (max-width:1023px) {
    .visible-sm {
        display: block !important
    }

    table.visible-sm {
        display: table !important
    }

    tr.visible-sm {
        display: table-row !important
    }

    td.visible-sm,
    th.visible-sm {
        display: table-cell !important
    }
}

@media (min-width:768px) and (max-width:1023px) {
    .visible-sm-block {
        display: block !important
    }
}

@media (min-width:768px) and (max-width:1023px) {
    .visible-sm-inline {
        display: inline !important
    }
}

@media (min-width:768px) and (max-width:1023px) {
    .visible-sm-inline-block {
        display: inline-block !important
    }
}

@media (min-width:1024px) and (max-width:1249px) {
    .visible-md {
        display: block !important
    }

    table.visible-md {
        display: table !important
    }

    tr.visible-md {
        display: table-row !important
    }

    td.visible-md,
    th.visible-md {
        display: table-cell !important
    }
}

@media (min-width:1024px) and (max-width:1249px) {
    .visible-md-block {
        display: block !important
    }
}

@media (min-width:1024px) and (max-width:1249px) {
    .visible-md-inline {
        display: inline !important
    }
}

@media (min-width:1024px) and (max-width:1249px) {
    .visible-md-inline-block {
        display: inline-block !important
    }
}

@media (min-width:1250px) {
    .visible-lg {
        display: block !important
    }

    table.visible-lg {
        display: table !important
    }

    tr.visible-lg {
        display: table-row !important
    }

    td.visible-lg,
    th.visible-lg {
        display: table-cell !important
    }
}

@media (min-width:1250px) {
    .visible-lg-block {
        display: block !important
    }
}

@media (min-width:1250px) {
    .visible-lg-inline {
        display: inline !important
    }
}

@media (min-width:1250px) {
    .visible-lg-inline-block {
        display: inline-block !important
    }
}

@media (max-width:767px) {
    .hidden-xs {
        display: none !important
    }
}

@media (min-width:768px) and (max-width:1023px) {
    .hidden-sm {
        display: none !important
    }
}

@media (min-width:1024px) and (max-width:1249px) {
    .hidden-md {
        display: none !important
    }
}

@media (min-width:1250px) {
    .hidden-lg {
        display: none !important
    }
}

.visible-print {
    display: none !important
}

@media print {
    .visible-print {
        display: block !important
    }

    table.visible-print {
        display: table !important
    }

    tr.visible-print {
        display: table-row !important
    }

    td.visible-print,
    th.visible-print {
        display: table-cell !important
    }
}

.visible-print-block {
    display: none !important
}

@media print {
    .visible-print-block {
        display: block !important
    }
}

.visible-print-inline {
    display: none !important
}

@media print {
    .visible-print-inline {
        display: inline !important
    }
}

.visible-print-inline-block {
    display: none !important
}

@media print {
    .visible-print-inline-block {
        display: inline-block !important
    }
}

@media print {
    .hidden-print {
        display: none !important
    }
}

.btn,
.button,
.cookieBar__button {
    display: inline-block;
    vertical-align: middle;
    padding: 10px 30px;
    line-height: 26px;
    min-width: 260px;
    max-width: 100%;
    font-size: 16px;
    letter-spacing: .1em;
    border: 2px solid #ff9027;
    color: #ff9027;
    cursor: pointer;
    background: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 24px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box
}

.btn:focus,
.btn:hover,
.button:focus,
.button:hover,
.cookieBar__button:focus,
.cookieBar__button:hover,
.link:hover .button,
.link:hover .cookieBar__button {
    background: #ff4219;
    border-color: #ff4219;
    color: #fff;
    text-decoration: none
}

.btn:active,
.button:active,
.cookieBar__button:active {
    background: #ed1800;
    border-color: #ed1800;
    color: #fff;
    text-decoration: none
}

@media (max-width:767px) {

    .btn,
    .button,
    .cookieBar__button {
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px
    }
}

@media (max-width:480px) {

    .btn,
    .button,
    .cookieBar__button {
        min-width: 200px
    }
}

.btn-primary,
.button_primary {
    display: inline-block;
    vertical-align: middle;
    padding: 10px 30px;
    line-height: 26px;
    min-width: 260px;
    max-width: 100%;
    font-size: 16px;
    letter-spacing: .1em;
    border: 2px solid #ff9027;
    color: #ff9027;
    cursor: pointer;
    background: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 24px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: #ff9027;
    color: #fff;
    border-color: #ff9027
}

.btn-primary:focus,
.btn-primary:hover,
.button_primary:focus,
.button_primary:hover {
    background: #ff4219;
    border-color: #ff4219;
    color: #fff;
    text-decoration: none
}

.btn-primary:active,
.button_primary:active {
    background: #ed1800;
    border-color: #ed1800;
    color: #fff;
    text-decoration: none
}

@media (max-width:767px) {

    .btn-primary,
    .button_primary {
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px
    }
}

@media (max-width:480px) {

    .btn-primary,
    .button_primary {
        min-width: 200px
    }
}

.btn-primary:focus,
.btn-primary:hover,
.button_primary:focus,
.button_primary:hover {
    background: #ff4219;
    border-color: #ff4219;
    color: #fff
}

.btn-primary:active,
.button_primary:active {
    background: #ed1800;
    border-color: #ed1800;
    color: #fff
}

.btn-secondary,
.button_secondary {
    display: inline-block;
    vertical-align: middle;
    padding: 10px 30px;
    line-height: 26px;
    min-width: 260px;
    max-width: 100%;
    font-size: 16px;
    letter-spacing: .1em;
    border: 2px solid #ff9027;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 24px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: #fff;
    color: #ff9027;
    border-color: #ff9027
}

.btn-secondary:focus,
.btn-secondary:hover,
.button_secondary:focus,
.button_secondary:hover {
    background: #ff4219;
    border-color: #ff4219;
    color: #fff;
    text-decoration: none
}

.btn-secondary:active,
.button_secondary:active {
    background: #ed1800;
    border-color: #ed1800;
    color: #fff;
    text-decoration: none
}

@media (max-width:767px) {

    .btn-secondary,
    .button_secondary {
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px
    }
}

@media (max-width:480px) {

    .btn-secondary,
    .button_secondary {
        min-width: 200px
    }
}

.btn-secondary:focus,
.btn-secondary:hover,
.button_secondary:focus,
.button_secondary:hover {
    background: #ff9027;
    border-color: #ff9027;
    color: #fff
}

.btn-secondary:active,
.button_secondary:active {
    background: #ed1800;
    border-color: #ed1800;
    color: #fff
}

.button_hidden {
    display: none;
}

.btn-action,
.button_action {
    display: inline-block;
    vertical-align: middle;
    padding: 10px 30px;
    line-height: 26px;
    min-width: 260px;
    max-width: 100%;
    font-size: 16px;
    letter-spacing: .1em;
    border: 2px solid #ff9027;
    color: #ff9027;
    cursor: pointer;
    background: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 24px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: #ff4219;
    color: #fff;
    border-color: #ff4219
}

.btn-action:focus,
.btn-action:hover,
.button_action:focus,
.button_action:hover,
.link:hover .button_action {
    background: #ff4219;
    border-color: #ff4219;
    color: #fff;
    text-decoration: none
}

.btn-action:active,
.button_action:active {
    background: #ed1800;
    border-color: #ed1800;
    color: #fff;
    text-decoration: none
}

@media (max-width:767px) {

    .btn-action,
    .button_action {
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px
    }
}

@media (max-width:480px) {

    .btn-action,
    .button_action {
        min-width: 200px
    }
}

.btn-action:focus,
.btn-action:hover,
.button_action:focus,
.button_action:hover,
.link:hover .button_action {
    background: #e52900;
    border-color: #e52900;
    color: #fff
}

.btn-action:active,
.button_action:active {
    background: #cc2400;
    border-color: #cc2400;
    color: #fff
}

.btn-disabled,
.button_disabled {
    display: inline-block;
    vertical-align: middle;
    padding: 10px 30px;
    line-height: 26px;
    min-width: 260px;
    max-width: 100%;
    font-size: 16px;
    letter-spacing: .1em;
    border: 2px solid #ff9027;
    color: #ff9027;
    cursor: pointer;
    background: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 24px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: #eee;
    color: #999;
    border-color: #eee;
    cursor: not-allowed;
}

.btn-disabled:focus,
.btn-disabled:hover,
.button_disabled:focus,
.button_disabled:hover {
    background: #ff4219;
    border-color: #ff4219;
    color: #fff;
    text-decoration: none
}

.btn-disabled:active,
.button_disabled:active {
    background: #ed1800;
    border-color: #ed1800;
    color: #fff;
    text-decoration: none
}

@media (max-width:767px) {

    .btn-disabled,
    .button_disabled {
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px
    }
}

@media (max-width:480px) {

    .btn-disabled,
    .button_disabled {
        min-width: 200px
    }
}

.btn-disabled:active,
.btn-disabled:focus,
.btn-disabled:hover,
.button_disabled:active,
.button_disabled:focus,
.button_disabled:hover {
    background: #eee;
    border-color: #eee;
    color: #999
}

.button--icon-left {
    position: relative;
    padding-left: 35px;
    min-width: 0
}

.button--icon-left .icon {
    position: absolute;
    top: 50%;
    line-height: 20px;
    width: 20px;
    margin-top: -10px;
    left: 10px
}

.button--icon-right {
    position: relative;
    padding-right: 35px;
    min-width: 0
}

.button--icon-right .icon {
    position: absolute;
    top: 50%;
    line-height: 20px;
    width: 20px;
    margin-top: -10px;
    right: 10px
}

.button_full {
    width: 100%;
    min-width: 0
}

.button_row,
.button_row_bottom,
.button_row_top {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: -5px;
    margin-right: -5px
}

.button_row:after,
.button_row_bottom:after,
.button_row_top:after {
    clear: both;
    display: block;
    content: " "
}

.button_row .button_paypalexpress,
.button_row_bottom .button_paypalexpress,
.button_row_top .button_paypalexpress {
    display: inline-block
}

.button_row .button,
.button_row .button_paypalexpress,
.button_row .cookieBar__button,
.button_row_bottom .button,
.button_row_bottom .button_paypalexpress,
.button_row_top .button,
.button_row_top .button_paypalexpress {
    margin: 10px 5px
}

@media (max-width:480px) {

    .button_row,
    .button_row_bottom,
    .button_row_top {
        text-align: center
    }

    .button_row .pull-left,
    .button_row .pull-right,
    .button_row_bottom .pull-left,
    .button_row_bottom .pull-right,
    .button_row_top .pull-left,
    .button_row_top .pull-right {
        float: none !important
    }

    .button_row .button,
    .button_row .cookieBar__button,
    .button_row_bottom .button,
    .button_row_top .button {
        min-width: 220px
    }
}

.button_row_bottom {
    margin-bottom: 0
}

.button_row_bottom .button,
.button_row_bottom .button_paypalexpress,
.button_row_bottom .cookieBar__button {
    margin-bottom: 0
}

.button_row_top {
    margin-top: 0
}

.button_row_top .button,
.button_row_top .button_paypalexpress,
.button_row_top .cookieBar__button {
    margin-top: 0
}

.button-min-width {
    min-width: 270px
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important
}

.labelFormGroup {
    margin: 0;
    color: #0d0200;
    margin-bottom: 5px
}

::-webkit-input-placeholder {
    color: rgba(13, 2, 0, .5);
    opacity: 1 !important
}

::-moz-placeholder {
    color: rgba(13, 2, 0, .5);
    opacity: 1 !important
}

:-ms-input-placeholder {
    color: rgba(13, 2, 0, .5);
    opacity: 1 !important
}

:-moz-placeholder {
    color: rgba(13, 2, 0, .5);
    opacity: 1 !important
}

.inputHelper,
input,
select,
textarea {
    background-color: rgba(255, 255, 255, .3);
    padding: 10px;
    border: 1px solid rgba(13, 2, 0, .5);
    color: #0d0200;
    border-radius: 4px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-box-shadow: none;
    box-shadow: none;
    line-height: 24px;
    display: block;
    width: 100%;
    font-size: 17px;
    -webkit-transition: .2s ease-in-out;
    -moz-transition: .2s ease-in-out;
    -ms-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out
}

.inputHelper:not(:disabled):active,
.inputHelper:not(:disabled):focus,
input:not(:disabled):active,
input:not(:disabled):focus,
select:not(:disabled):active,
select:not(:disabled):focus,
textarea:not(:disabled):active,
textarea:not(:disabled):focus {
    outline: 0;
    border-color: #ff9027
}

.inputHelper.error,
input.error,
select.error,
textarea.error {
    border-color: #ed1800
}

@media (max-width:1579px) {

    .inputHelper,
    input,
    select,
    textarea {
        font-size: 16px;
        line-height: 20px
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

input[type=submit] {
    width: auto
}

input[type=number] {
    -moz-appearance: textfield
}

input[type=file] {
    padding: 0;
    border: 0
}

.radio input[type=radio] {
    margin-top: 12px
}

textarea {
    resize: vertical;
    min-height: 150px
}

.select_body {
    background-color: rgba(255, 255, 255, .3);
    border-radius: 24px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    cursor: pointer;
    display: inline-block;
    width: auto;
    position: relative
}

.select_body:after {
    content: "\E01C";
    font-family: icons-new2;
    position: absolute;
    right: 15px;
    top: 50%;
    line-height: 20px;
    margin-top: -10px;
    font-size: 20px;
    color: #0d0200;
    pointer-events: none
}

.select_body select {
    background-color: transparent;
    color: #0d0200;
    cursor: pointer;
    padding-right: 40px;
    -o-appearance: none;
    -khtml-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    border-color: transparent
}

.select_body select::-ms-expand {
    display: none
}

.select_body.focus select {
    border-color: #fff
}

.select_body.disabled select {
    cursor: default
}

@media (max-width:1579px) {
    .select_body {
        border-radius: 22px;
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box
    }
}

.form-group,
.form-group-set {
    margin-bottom: 20px
}

.form-group .select_body,
.form-group input,
.form-group textarea,
.form-group-set .select_body,
.form-group-set input,
.form-group-set textarea {
    width: 100%
}

.form-group-set.has-danger input,
.form-group.has-danger input {
    border-color: #ed1800;
    color: #ed1800
}

.form-group label,
.form-group-set label {
    margin: 0;
    color: #0d0200;
    margin-bottom: 5px
}

@media (max-width:767px) {

    .form-group,
    .form-group-set {
        margin-bottom: 20px
    }
}

.form-group--with-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-align: stretch;
    -webkit-box-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.form-group--with-button>input {
    width: auto;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.form-group--with-button .button,
.form-group--with-button .cookieBar__button {
    width: 130px;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    min-width: 0;
    margin-left: 10px
}

@media (max-width:480px) {

    .form-group--with-button .button,
    .form-group--with-button .cookieBar__button {
        width: 100%;
        margin-left: 0;
        margin-top: -1px
    }
}

@media (min-width:1024px) and (max-width:1249px) {

    .col-md-4 .form-group--with-button .button,
    .col-md-4 .form-group--with-button .cookieBar__button {
        width: 100%;
        margin-left: 0;
        margin-top: -1px
    }
}

.checkbox-group {
    margin-bottom: 20px
}

@media (max-width:767px) {
    .checkbox-group {
        margin-bottom: 20px
    }
}

.specialcheckbox,
.specialradiobox {
    position: relative;
    line-height: 20px;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 10px;
    padding-left: 50px;
    color: #0d0200
}

.specialcheckbox label,
.specialradiobox label {
    font-style: normal;
    position: absolute;
    left: 0;
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, .3);
    border: 0;
    border-radius: 50%;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    display: block;
    pointer-events: none;
    margin-bottom: 0;
    font-family: 'Work Sans', Helvetica, Arial, sans-serif
}

.specialcheckbox label:after,
.specialradiobox label:after {
    content: " ";
    pointer-events: none;
    position: absolute;
    width: 22px;
    height: 22px;
    left: 4px;
    top: 4px;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -webkit-transition: opacity .1s ease-in;
    -moz-transition: opacity .1s ease-in;
    -ms-transition: opacity .1s ease-in;
    -o-transition: opacity .1s ease-in;
    background-color: #ff9027;
    border-radius: 50%;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    display: block
}

.specialcheckbox input:not(.noCheckbox),
.specialradiobox input:not(.noCheckbox) {
    position: absolute;
    width: 0;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    cursor: pointer;
    margin: 0
}

.specialcheckbox input:not(.noCheckbox):checked+label:after,
.specialradiobox input:not(.noCheckbox):checked+label:after {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1
}

.specialcheckbox input:not(.noCheckbox):disabled+label:after,
.specialradiobox input:not(.noCheckbox):disabled+label:after {
    color: #a61a00
}

.specialcheckbox:active,
.specialradiobox:active {
    color: #ff9027
}

.specialcheckbox:active label:after,
.specialradiobox:active label:after {
    -webkit-opacity: .7;
    -moz-opacity: .7;
    opacity: .7
}

.specialcheckbox:active input:not(.noCheckbox):disabled:not(:checked)+label:after,
.specialradiobox:active input:not(.noCheckbox):disabled:not(:checked)+label:after {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0
}

.specialcheckbox.disabled,
.specialradiobox.disabled {
    cursor: default;
    color: #0d0200;
    opacity: 50%;
}

.specialcheckbox label:after {
    left: 0;
    content: "\E01B";
    font-family: icons-new2;
    color: #0d0200;
    top: 0;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    line-height: 30px;
    -webkit-transition: opacity .1s ease-in;
    -moz-transition: opacity .1s ease-in;
    -ms-transition: opacity .1s ease-in;
    -o-transition: opacity .1s ease-in;
    font-size: 30px;
    background-color: transparent
}

.specialcheckbox input:not(.noCheckbox) {
    width: 20px;
    height: 20px;
    left: 0;
    top: 0
}

.form-groups-inline {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.form-groups-inline>.form-group,
.form-groups-inline>.form-group-set {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px
}

@media (max-width:767px) {
    .form-groups-inline {
        margin-bottom: 10px
    }
}

.form-group-set {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-align: stretch;
    -webkit-box-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px
}

.form-group-set>label {
    padding-left: 5px;
    padding-right: 5px
}

.form-group-set .form-group,
.form-group-set .form-group-set {
    margin-bottom: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-left: 5px;
    padding-right: 5px
}

.form-group-set .form-group-set-item__small {
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    width: 130px
}

@media (max-width:767px) {
    .form-group-set .form-group-set-item__small {
        width: 100px
    }
}

.form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group),
.form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>label,
.form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>label {
    width: 160px;
    padding-right: 10px;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>.label-filename,
.form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>.select_body,
.form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>input,
.form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>textarea,
.form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>.label-filename,
.form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>.select_body,
.form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>input,
.form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>textarea {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: auto
}

.form-label-left .form-group-button {
    padding-left: 160px
}

.form-label-left .form-group-button .button,
.form-label-left .form-group-button .cookieBar__button {
    width: auto
}

.form-label-left .form-group-set {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.form-label-left .form-group-set>label {
    width: 160px;
    padding-right: 10px;
    -ms-flex-negative: 1;
    flex-shrink: 1
}

.form-label-left .checkbox-group,
.form-label-left .radiobox-group {
    padding-left: 160px
}

@media (max-width:480px) {

    .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group),
    .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group) {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>label,
    .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>label {
        width: 100%;
        padding-right: 0
    }

    .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>.label-filename,
    .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>.select_body,
    .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>input,
    .form-label-left .form-group-set:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>textarea,
    .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>.label-filename,
    .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>.select_body,
    .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>input,
    .form-label-left .form-group:not(.form-group-set-item):not(.form-group-button):not(.form-group-set-item__small):not(.radiobox-group):not(.checkbox-group)>textarea {
        width: 100%
    }

    .form-label-left .form-group-button {
        padding-left: 0;
        text-align: center
    }

    .form-label-left .form-group-set>label {
        width: 100%;
        padding-right: 0
    }

    .form-label-left .checkbox-group,
    .form-label-left .radiobox-group {
        padding-left: 0
    }
}

.modal .checkbox-group,
.modal .form-group,
.modal .form-group-set {
    margin-bottom: 10px
}

.modal .form-group-set .form-group,
.modal .form-group-set .form-group-set {
    margin-bottom: 0
}

.table_area .form-group,
.table_area .form-group-set {
    margin-bottom: 0
}

.table_area .form-groups-inline {
    margin-bottom: 0
}

.inputfile {
    width: .1px;
    height: .1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1
}

.inputfile+label {
    background-color: rgba(255, 255, 255, .3);
    padding: 10px;
    border: 1px solid rgba(13, 2, 0, .5);
    color: #0d0200;
    border-radius: 4px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-box-shadow: none;
    box-shadow: none;
    line-height: 24px;
    display: block;
    width: 100%;
    font-size: 17px;
    -webkit-transition: .2s ease-in-out;
    -moz-transition: .2s ease-in-out;
    -ms-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: not-allowed;
    display: inline-block;
    overflow: hidden;
    max-width: none;
    padding-left: 40px;
    margin-bottom: 0;
    position: relative;
    background-color: #eee
}

.inputfile+label:not(:disabled):active,
.inputfile+label:not(:disabled):focus {
    outline: 0;
    border-color: #ff9027
}

.inputfile+label.error {
    border-color: #ed1800
}

@media (max-width:1579px) {
    .inputfile+label {
        font-size: 16px;
        line-height: 20px
    }
}

.inputfile+label:after,
.inputfile+label:before {
    content: " ";
    position: absolute;
    left: 10px;
    top: 50%;
    display: block;
    width: 16px;
    height: 2px;
    margin-top: -1px;
    background-color: #0d0200
}

.inputfile+label:after {
    width: 2px;
    height: 16px;
    margin-top: -8px;
    margin-left: 7px
}

.inputfile:not(:disabled)+label {
    cursor: pointer;
    background-color: #fff
}

.inputfile:not(:disabled)+label:active,
.inputfile:not(:disabled)+label:focus {
    outline: 0
}

.inputfile:not(:disabled)+label:hover:after,
.inputfile:not(:disabled)+label:hover:before {
    background-color: #ff9027
}

.inputfile:not(:disabled)+label:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #999
}

.inputButtonForm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.inputButtonForm .form-group,
.inputButtonForm .form-group-set {
    margin-bottom: 0
}

.inputButtonForm__input {
    width: calc(100% - 140px);
    width: -o-calc(100% - 140px)
}

.inputButtonForm__input label {
    display: block;
    width: 100%
}

.inputButtonForm__button {
    width: 140px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-left: 10px
}

.inputButtonForm__button .button,
.inputButtonForm__button .cookieBar__button {
    width: 100%;
    min-width: 0
}

.inputButtonForm__button .button:not(.button_disabled),
.inputButtonForm__button .cookieBar__button:not(.button_disabled) {
    display: inline-block;
    vertical-align: middle;
    padding: 10px 30px;
    line-height: 26px;
    min-width: 260px;
    max-width: 100%;
    font-size: 16px;
    letter-spacing: .1em;
    border: 2px solid #ff9027;
    color: #ff9027;
    cursor: pointer;
    background: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 24px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: #ff9027;
    color: #fff;
    border-color: #ff9027;
    min-width: 0
}

.inputButtonForm__button .button:not(.button_disabled):focus,
.inputButtonForm__button .button:not(.button_disabled):hover,
.inputButtonForm__button .cookieBar__button:not(.button_disabled):focus,
.inputButtonForm__button .cookieBar__button:not(.button_disabled):hover {
    background: #ff4219;
    border-color: #ff4219;
    color: #fff;
    text-decoration: none
}

.inputButtonForm__button .button:not(.button_disabled):active,
.inputButtonForm__button .cookieBar__button:not(.button_disabled):active {
    background: #ed1800;
    border-color: #ed1800;
    color: #fff;
    text-decoration: none
}

@media (max-width:767px) {

    .inputButtonForm__button .button:not(.button_disabled),
    .inputButtonForm__button .cookieBar__button:not(.button_disabled) {
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px
    }
}

@media (max-width:480px) {

    .inputButtonForm__button .button:not(.button_disabled),
    .inputButtonForm__button .cookieBar__button:not(.button_disabled) {
        min-width: 200px
    }
}

.inputButtonForm__button .button:not(.button_disabled):focus,
.inputButtonForm__button .button:not(.button_disabled):hover,
.inputButtonForm__button .cookieBar__button:not(.button_disabled):focus,
.inputButtonForm__button .cookieBar__button:not(.button_disabled):hover {
    background: #ff4219;
    border-color: #ff4219;
    color: #fff
}

.inputButtonForm__button .button:not(.button_disabled):active,
.inputButtonForm__button .cookieBar__button:not(.button_disabled):active {
    background: #ed1800;
    border-color: #ed1800;
    color: #fff
}

@media (max-width:480px) {
    .inputButtonForm {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .inputButtonForm__input {
        width: 100%
    }

    .inputButtonForm__button {
        width: 100%;
        padding-left: 0
    }
}

.inputButtonForm--small .inputButtonForm__input {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.inputButtonForm--small .inputButtonForm__button {
    width: 50px;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.inputButtonForm--small .inputButtonForm__button .button,
.inputButtonForm--small .inputButtonForm__button .cookieBar__button {
    padding: 0;
    line-height: 38px
}

.inputButtonForm--small .inputButtonForm__button .button .icon,
.inputButtonForm--small .inputButtonForm__button .cookieBar__button .icon {
    display: block;
    line-height: 38px
}

@media (max-width:480px) {
    .inputButtonForm--small {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }
}

.formOffer {
    margin-bottom: 60px
}

.formOffer .form-group,
.formOffer .form-group-set {
    margin-bottom: 10px
}

.formOffer .form-group-set.error label,
.formOffer .form-group.error label {
    color: #ed1800
}

.formOffer .form-group-set.error .inputHelper,
.formOffer .form-group-set.error input,
.formOffer .form-group-set.error select,
.formOffer .form-group-set.error textarea,
.formOffer .form-group.error .inputHelper,
.formOffer .form-group.error input,
.formOffer .form-group.error select,
.formOffer .form-group.error textarea {
    border: 1px solid #ed1800 !important;
    outline: 0 !important;
    background: #fff !important
}

.formOffer .inputHelper,
.formOffer input,
.formOffer select,
.formOffer textarea {
    border: 1px solid transparent;
    background: #fff4e9;
    padding-left: 20px
}

.formOffer .inputHelper:active,
.formOffer .inputHelper:focus,
.formOffer input:active,
.formOffer input:focus,
.formOffer select:active,
.formOffer select:focus,
.formOffer textarea:active,
.formOffer textarea:focus {
    border: 1px solid #ff9027;
    outline: 0;
    background: #fff
}

.formOffer .form-group label,
.formOffer .form-group-set label {
    font-size: 13px
}

.cookieBar {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .8);
    font-size: 12px;
    padding: 10px;
    width: 100%;
    z-index: 100;
    text-align: center;
    min-width: 320px;
    color: #0d0200
}

.cookieBar>div {
    display: inline-block
}

.cookieBar__message {
    display: inline-block;
    padding-right: 10px
}

.cookieBar__button {
    min-width: 0;
    width: 100px
}

@media (max-width:1579px) {
    .cookieBar>div {
        display: block
    }

    .cookieBar__message {
        display: block;
        padding-right: 0;
        margin-bottom: 10px
    }
}

a:focus {
    outline: 0
}

img {
    height: auto !important;
    max-width: 100% !important
}

.image {
    display: block;
    max-width: 100%;
    position: relative;
    width: 100%;
    background-color: #fff
}

.image img {
    bottom: 0;
    height: auto;
    left: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: auto
}

.image:before {
    content: "";
    display: block;
    padding-top: 100%
}

#container {
    padding-top: 100px
}

@media (max-width:1249px) {
    #container {
        padding-top: 80px
    }
}

main {
    min-height: calc(100vh - 70px);
    min-height: -o-calc(100vh - 70px)
}

#overlay {
    display: none;
    background-color: rgba(153, 153, 153, .2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 98
}

.link {
    cursor: pointer;
    color: #0d0200
}

@media (max-width:1023px) {
    .container {
        padding-left: 20px;
        padding-right: 20px
    }
}

iframe {
    max-width: 100%
}

.fadeInframe {
    display: none
}

.progressCircle {
    margin: auto;
    width: 150px;
    height: 150px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background-color: #fff4e9;
    border-radius: 50%
}

.progressCircle .progressCircle__fill,
.progressCircle .progressCircle__slice {
    width: 150px;
    height: 150px;
    position: absolute;
    -webkit-backface-visibility: hidden;
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    border-radius: 50%
}

.progressCircle .progressCircle__slice {
    clip: rect(0, 150px, 150px, 75px)
}

.progressCircle .progressCircle__slice .progressCircle__fill {
    clip: rect(0, 75px, 150px, 0);
    background-color: #ff9027
}

.progressCircle .progressCircle__overlay {
    width: 120px;
    height: 120px;
    position: absolute;
    margin-left: 15px;
    margin-top: 15px;
    font-size: 34px;
    background-color: #fff;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media (max-width:767px) {
    .progressCircle {
        width: 100px;
        height: 100px
    }

    .progressCircle .progressCircle__fill,
    .progressCircle .progressCircle__slice {
        width: 100px;
        height: 100px
    }

    .progressCircle .progressCircle__slice {
        clip: rect(0, 100px, 100px, 50px)
    }

    .progressCircle .progressCircle__slice .progressCircle__fill {
        clip: rect(0, 50px, 100px, 0)
    }

    .progressCircle .progressCircle__overlay {
        width: 80px;
        height: 80px;
        margin-left: 10px;
        margin-top: 10px;
        font-size: 22px
    }
}

header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

header .headerLeft {
    padding-top: 30px;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

@media (max-width:1023px) {
    header .headerLeft {
        padding-left: 20px
    }
}

@media (max-width:480px) {
    header .headerLeft {
        -ms-flex-negative: 1;
        flex-shrink: 1
    }

    header .headerLeft .headerBackToHomepage {
        -ms-flex-negative: 0;
        flex-shrink: 0
    }
}

header .headerLeft .linkWithIcon {
    padding-left: 22px
}

header .headerLeft .linkWithIcon .icon {
    rotate: 90deg;
    font-size: 12px;
    line-height: 12px;
    margin-top: -6px;
    width: 20px
}

header .headerLogo {
    margin-right: 25px
}

header .headerRight {
    padding-right: 110px;
    padding-top: 20px
}

@media (max-width:1579px) {
    header .headerRight {
        padding-right: 40px
    }
}

@media (max-width:1249px) {
    header .headerRight .button_secondary {
        padding: 10px;
        min-width: 1px;
        letter-spacing: 0;
        font-size: 13px
    }
}

.configuratorWrapper {
    display: grid;
    grid-template-columns: 4fr 450px;
    padding-left: 20px;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto
}

.configuratorWrapper .select_body {
    background: #fff4e9;
    border-radius: 6px
}

main .configuratorMainArea {
    padding-left: 110px;
    padding-right: 110px;
    padding-bottom: 20px
}

main .configuratorMainArea__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

main .configuratorMainArea .configuratorIntro {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-top: 90px;
    margin-bottom: 35px
}

@media (max-width:767px) {
    main .configuratorMainArea .configuratorIntro {
        margin-top: 40px;
        margin-bottom: 20px
    }
}

main .configuratorMainArea .button_row,
main .configuratorMainArea .button_row_bottom,
main .configuratorMainArea .button_row_top {
    margin-bottom: 40px;
    margin-top: 0
}

@media (max-width:767px) {

    main .configuratorMainArea .button_row,
    main .configuratorMainArea .button_row_bottom,
    main .configuratorMainArea .button_row_top {
        margin-bottom: 20px
    }
}

main .configuratorInputs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 35px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 10px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

main .configuratorInputs>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

main .configuratorInputs__area {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    grid-gap: 10px
}

main .configuratorInputs__quantity {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    grid-gap: 10px
}

main .configuratorInputs__hint {
    max-width: 330px;
    font-size: 15px
}

main .configuratorInputs .inputHelper,
main .configuratorInputs input,
main .configuratorInputs select,
main .configuratorInputs textarea {
    max-width: 90px;
    text-align: center;
    line-height: 26px;
    padding-left: 6px
}

main .configuratorPages {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

main * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

aside {
    padding: 180px 30px 30px 30px;
    background-color: #fff4e9;
    font-size: 15px
}

aside h3 {
    margin-bottom: 60px !important
}

/* aside .buyBox__overview {
    position: sticky;
    top: 60px
} */

aside .overviewItem {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    gap: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ff9027;
    margin-bottom: 20px
}

.overviewItemPrices {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding-bottom: none;
    border-bottom: none;
    margin-bottom: 1px;
}

.overviewItemRebate {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ff9027;
    margin-bottom: 20px
}


aside .overviewItem__image {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    /* background: #fff; */
    width: 90px;
    height: 90px;
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

aside .overviewItem__image:before {
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ff4219;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    content: "\E01B";
    font-family: icons-new2
}

aside .overviewItem__image img {
    -o-object-fit: contain;
    object-fit: contain;
    border-radius: 6px
}

aside .overviewItem__content {
    width: 100%
}

aside .overviewItem__description {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

aside .overviewItem__price {
    font-size: 17px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    text-align: right
}

aside .overviewItem__link {
    position: absolute;
    top: 0;
    right: 0
}

aside .overviewItem__link a {
    text-decoration: underline
}

aside .overviewItem__headline {
    font-weight: 700
}

aside .overviewItem__options {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    line-height: 1.2;
    padding-bottom: 10px;
    margin-bottom: 10px
}

aside .overviewItem__options--noBorder {
    border: 0;
    padding-bottom: 0;
    margin-bottom: 10px
}

aside .overviewItem__options--total {
    padding-top: 10px;
    font-weight: 700
}

@media (max-width:1579px) {
    .configuratorWrapper {
        grid-template-columns: 3fr 320px
    }

    main .configuratorMainArea {
        padding-left: 0;
        padding-right: 40px
    }
}

@media (max-width:1023px) {
    .configuratorWrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-right: 0;
        padding-left: 0
    }

    main {
        min-height: unset;
        margin-bottom: 20px
    }

    main .configuratorMainArea {
        padding-left: 30px;
        padding-right: 30px
    }

    main .configuratorInputs {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    main .configuratorInputs>div {
        margin-bottom: 10px;
        width: 100%;
        max-width: 100%
    }

    main .configuratorInputs .configuratorInputs__quantity>div:first-child {
        width: 133px
    }

    aside {
        padding-top: 30px
    }
}

@media (max-width:767px) {
    .configuratorPages {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .configuratorPages>a {
        min-width: 260px
    }

    .configuratorPages .finish,
    .configuratorPages .next {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
        margin-bottom: 20px
    }
}

.configuratorContentCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    margin-bottom: 60px
}

@media (max-width:1500px) {
    .configuratorContentCards {
        grid-template-columns: repeat(2, 1fr)
    }
}

.configuratorContentCards .configuratorContentCard__item {
    border: 4px solid transparent;
    background: #fff;
    -webkit-box-shadow: 0 0 20px #0000001A;
    box-shadow: 0 0 20px #0000001A;
    border-radius: 18px;
    padding: 25px;
    font-size: 15px;
    padding-bottom: 70px;
    position: relative;
    -webkit-transition: .2s border ease-in-out;
    -moz-transition: .2s border ease-in-out;
    -ms-transition: .2s border ease-in-out;
    -o-transition: .2s border ease-in-out
}

.configuratorContentCards .configuratorContentCard__item.disabled,
.configuratorContentCards .configuratorContentCard__item.disabled:hover {
    opacity: 50%;
    cursor: default;
    border: none;
}

.configuratorContentCards .configuratorContentCard__item.disabled .specialradiobox {
    cursor: default;
}

.configuratorContentCards .configuratorContentCard__item .specialcheckbox,
.configuratorContentCards .configuratorContentCard__item .specialradiobox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
    padding: 0
}

.configuratorContentCards .configuratorContentCard__item .specialcheckbox label,
.configuratorContentCards .configuratorContentCard__item .specialradiobox label {
    left: unset;
    right: 25px;
    top: unset;
    bottom: 5px;
    border: 1px solid #999;
    -webkit-transition: .2s border ease-in-out;
    -moz-transition: .2s border ease-in-out;
    -ms-transition: .2s border ease-in-out;
    -o-transition: .2s border ease-in-out
}

.configuratorContentCards .configuratorContentCard__item .specialcheckbox label:after,
.configuratorContentCards .configuratorContentCard__item .specialradiobox label:after {
    top: -1px;
    left: -1px;
    width: 30px;
    height: 30px;
    content: "\E01B";
    font-family: icons-new2;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 24px
}

.configuratorContentCards .configuratorContentCard__item .specialcheckbox input:checked+label,
.configuratorContentCards .configuratorContentCard__item .specialradiobox input:checked+label {
    border-color: #ff9027
}

.configuratorContentCards .configuratorContentCard__item:hover {
    border: 4px solid #ff9027
}

.configuratorContentCards .configuratorContentCard__item:active {
    border: 4px solid #ff4219
}

.configuratorContentCards .configuratorContentCard__item:active .specialcheckbox label:after,
.configuratorContentCards .configuratorContentCard__item:active .specialradiobox label:after {
    background: #ff4219
}

.configuratorContentCards .configuratorContentCard__item>.form-group,
.configuratorContentCards .configuratorContentCard__item>.form-group-set {
    margin-bottom: 0
}

.configuratorContentCards .configuratorContentCard__image {
    text-align: center;
    height: 220px;
    margin-bottom: 20px
}

.configuratorContentCards .configuratorContentCard__image img {
    height: 220px !important;
    width: 100% !important;
    -o-object-fit: contain;
    object-fit: contain
}

.configuratorContentCards .configuratorContentCard__subline {
    color: #ff9027;
    margin-bottom: 10px
}

.configuratorContentCards .configuratorContentCard__title {
    font-size: 20px;
    font-weight: 700
}

@media (max-width:767px) {
    .configuratorContentCards .configuratorContentCard__title {
        font-size: 18px
    }
}

.configuratorContentCards .configuratorContentCard__description {
    margin-bottom: 10px
}

.configuratorContentCards .configuratorContentCard__attributes ul {
    padding-left: 20px
}

.configuratorContentCards .configuratorContentCard__price {
    font-size: 22px;
    position: absolute;
    bottom: 20px
}

@media (max-width:767px) {
    .configuratorContentCards .configuratorContentCard__price {
        bottom: 15px
    }
}

@media (max-width:1023px) {
    .configuratorContentCards {
        overflow-y: hidden;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 30px;
        padding-right: 30px;
        margin-left: -30px;
        margin-right: -30px;
        margin-bottom: 0;
    }

    .configuratorContentCards .configuratorContentCard__item {
        scroll-snap-align: center;
        padding: 15px 15px 60px 15px;
        min-width: 40%
    }
}

@media (max-width:767px) {
    .configuratorContentCards .configuratorContentCard__item {
        min-width: 80%;
        font-size: 12px
    }
}

@media (max-width:480px) {
    .configuratorContentCards .configuratorContentCard__item {
        min-width: 92%
    }
}

.configuratorContentOptions .specialcheckbox {
    width: 100%;
    font-size: 15px;
    padding-left: 45px
}

.configuratorContentOptions .specialcheckbox label {
    border-radius: 6px;
    border: 1px solid #999
}

.configuratorContentOptions .specialcheckbox label:after {
    color: #fff
}

.configuratorContentOptions .specialcheckbox p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    margin: 0
}

.configuratorContentOptions .specialcheckbox p strong {
    display: block;
    width: 100%;
    font-size: 17px
}

.configuratorContentOptions .specialcheckbox .priceQuantityWrapper {
    display: grid;
    grid-template-columns: 110px 2fr
}

.configuratorContentOptions .specialcheckbox .priceWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    min-width: 137px
}

.configuratorContentOptions .specialcheckbox .priceWrapper .basePrice {
    font-size: 22px
}

@media (max-width:767px) {
    .configuratorContentOptions .specialcheckbox .priceWrapper .basePrice {
        font-size: 18px
    }
}

.configuratorContentOptions .specialcheckbox .priceWrapper .priceSubline {
    font-size: 12px
}

.configuratorContentOptions .specialcheckbox input:checked+label {
    background: #ff9027;
    border: 0
}

.configuratorContentOptions .specialcheckbox:hover {
    color: #ff9027
}

.configuratorContentOptions .withQuantity {
    display: grid;
    position: relative
}

.configuratorContentOptions .withQuantity .quantity {
    width: 90px;
    margin-right: 10px
}

.configuratorContentOptions .withQuantity .quantity input {
    text-align: center
}

@media (max-width:767px) {
    .configuratorContentOptions {
        padding-right: 0;
        margin-bottom: 30px !important
    }

    .configuratorContentOptions .specialcheckbox {
        font-size: 13px
    }

    .configuratorContentOptions .specialcheckbox label {
        top: 0;
        -webkit-transform: translate(0);
        transform: translate(0)
    }

    .configuratorContentOptions .specialcheckbox p {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        gap: 5px
    }

    .configuratorContentOptions .specialcheckbox p .priceWrapper {
        width: 100%;
        text-align: right
    }

    .configuratorContentOptions .specialcheckbox p .priceQuantityWrapper {
        width: 100%
    }

    .configuratorContentOptions .withQuantity {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative
    }

    .configuratorContentOptions .withQuantity .quantity {
        top: -30px
    }

    .configuratorContentOptions .withQuantity .quantity input {
        width: 90px
    }
}

.configuratorNavigation {
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 15px;
    background: #fff4e9
}

@media (min-width: 768px) {
    .configuratorNavigation {
        margin: 40px -20px;
        padding: 0;
        background: 0 0;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

.configuratorNavigation li {
    margin: 0;
    padding: 0 10px;
    list-style-type: none
}

@media (min-width: 768px) {
    .configuratorNavigation li {
        padding-left: 20px;
        padding-right: 20px
    }
}

.configuratorNavigation a {
    color: #0d0200
}

.configuratorNavigation a:active,
.configuratorNavigation a:focus,
.configuratorNavigation a:hover {
    color: #ff9027
}

.slidecontent {
    margin-bottom: 100px
}

.slidecontent .slide_container {
    margin-bottom: 20px
}

.slidecontent .slide_container .slidecontent_headline {
    position: relative;
    font-size: 22px;
    font-weight: 700;
    color: #0d0200;
    padding: 10px 10px 10px 45px;
    cursor: pointer
}

.slidecontent .slide_container .slidecontent_headline:after {
    content: "\E01C";
    font-family: icons-new2;
    position: absolute;
    left: 0;
    top: 50%;
    font-size: 30px;
    line-height: 20px;
    margin-top: -10px;
    width: 30px;
    text-align: center;
    color: #ff9027;
    -webkit-transition: .2s ease-in-out;
    -moz-transition: .2s ease-in-out;
    -ms-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    font-weight: 400
}

.slidecontent .slide_container .slidecontent_headline:active,
.slidecontent .slide_container .slidecontent_headline:focus,
.slidecontent .slide_container .slidecontent_headline:hover {
    color: #ff9027
}

.slidecontent .slide_container .slidecontent_headline.active:after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg)
}

@media (max-width:480px) {
    .slidecontent .slide_container .slidecontent_headline {
        font-size: 16px;
        padding-left: 30px
    }

    .slidecontent .slide_container .slidecontent_headline:after {
        font-size: 20px;
        width: 20px
    }
}

.slidecontent .slide_container .slidecontent_content_container {
    display: none;
    padding: 10px 0 20px 0
}

@media print {
    a[href]:after {
        content: none !important
    }
}