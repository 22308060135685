.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.4rem;
    width: 90vw;
    max-width: 400px;
    padding: 1.3rem;
    height: 350px;
    position: absolute;
    z-index: 2;
    top: 80%;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 15px;
    margin: auto;
    top: 0;
}

.modal h3 {
    text-align: center;
    font-weight: 500;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(1px);
    z-index: 1;
}

.hidden {
    display: none;
}